import { notify } from "@kyvg/vue3-notification";
import {
    AMPLITUDE_EVENTS,
    getEncryptionKey,
    getUserDetailsByToken,
    getUserDetailsByTokenNewImplementation,
    loginWithEmail,
    requestPasswordResetConfirmation,
    requestResetPaswordEmail,
    sendVerificationEmail,
    trackAmplitudeEvent,
    updateFeedback,
    validateMobile,
} from ".";
import { useUsersStore, useBusinessStore, useAuthStore } from "../stores";
import { fetchTeamMemberDetails } from "./queries/teamMembers";
import logger from "./logger";
import { useRouter } from "vue-router";
import { encryptToken } from "./encryptionKeys";

export const findRecommendedPlan = (
    teamMembers: number,
    isInternational: boolean,
    billingPeriod: string
) => {
    if (isInternational) {
        if (billingPeriod == "Quarterly") {
            return "International (Quarterly)";
        } else if (billingPeriod == "Annually") {
            return "International (Annual)";
        }
        return "International Plan";
    } else {
        if (teamMembers == 1) {
            if (billingPeriod == "Quarterly") {
                return "Solo Plan (Quarterly)";
            }
            if (billingPeriod == "Annually") {
                return "Solo Plan (Annual)";
            }

            return "Solo Plan";
        } else if (teamMembers > 1 && teamMembers <= 3) {
            if (billingPeriod == "Quarterly") {
                return "Standard Plan (Quarterly)";
            }
            if (billingPeriod == "Annually") {
                return "Standard Plan (Annual)";
            }

            return "Standard Plan";
        } else if (teamMembers > 3) {
            if (billingPeriod == "Quarterly") {
                return "Growth Plan (Quarterly)";
            }
            if (billingPeriod == "Annually") {
                return "Growth Plan (Annual)";
            }
            return "Growth Plan";
        }
    }
};

export const handleFetchUserDetails = async () => {
    try {
        const res = await getUserDetailsByToken();
        const response = await getUserDetailsByTokenNewImplementation();
        const businessStore = useBusinessStore();
        const userStore = useUsersStore();
        userStore.currentUser = res.data;
        userStore.currentUserBusinessLevel = response.data;
        if (businessStore.businesses?.length) {
            // checks the current active business and updates the exact active business on refresh
            const activeBusinessId = businessStore.activeBusiness?.business.id;
            businessStore.activeBusiness = businessStore?.businesses.find(
                (business: any) => business.business.id == activeBusinessId
            )!;
        }
        userStore.user_role = businessStore.activeBusiness?.role as string;
    } catch (error) {
        logger.error(error, "Error while Fetching User details");
    }
};
export const updateFeedbackSheet = async () => {
    await updateFeedback();
};
export const getPermissions = async () => {
    const businessStore = useBusinessStore();
    let userId = businessStore.currentUserId as number;
    try {
        const res = await fetchTeamMemberDetails(userId);
        return res.data.permission;
    } catch (error) {
        notify({
            text: "Unable to get permissions",
            type: "error",
        });
    }
};

export const handlePartnerSigin = async (callbackFunction: any) => {
    const authStore = useAuthStore();
    const router = useRouter();
    try {
        let queryString = window.location.href.split("?")[1];
        if (!queryString) return;

        let paramsArray = queryString.split("&");
        let queryParams = {} as any;

        for (let param of paramsArray) {
            let [key, value] = param.split("=");
            queryParams[key] = decodeURIComponent(value.replace(/\+/g, " "));
        }

        let payload: { mobile?: string; scheme?: string } = {
            scheme: "SMS",
        };
        if (queryParams.id) {
            payload = {
                mobile: validateMobile(queryParams.id),
            };
        }

        if (queryParams.trxref && authStore.mobile) {
            // update the payload with mobile number from signup page if redirected from paystack
            payload.mobile = authStore.mobile as string;
        }

        if (payload.mobile) {
            authStore.mobile = payload.mobile;
        }

        if (queryParams?.ref === "partner") {
            if (queryParams?.code === "WGH") {
                trackAmplitudeEvent(
                    AMPLITUDE_EVENTS.WGH_REFERALL_SIGNUP,
                    queryParams
                );
            }
        }

        if (queryParams?.ref === "go-global-signin" && authStore.mobile) {
            payload.mobile = authStore.mobile as string;
        }

        await callbackFunction(payload);
    } catch (error) {
        logger.error(error, "Error on signing in partners");
        router.push("/sign-in");
    }
};

export function removeDialCode(phoneNumber: string) {
    // Define an array of common international dial codes
    const dialCodes = ["+234", "+1", "+254", "234", "1", "254"];
    // Loop through the dial codes and check if the phone number starts with any of them
    for (const code of dialCodes) {
        if (phoneNumber.startsWith(code)) {
            // If a dial code is found, return the phone number with the dial code removed
            return phoneNumber.slice(code.length);
        }
    }
    // If no dial code is found, return the original phone number
    return phoneNumber;
}

export const validateAuthInput = (input: string) => {
    if (!input) return false
    const text = input.trim();
    if (text.length < 1) {
        return false;
    }

    if (text.replace(/[^a-zA-Z]/g, "").length < 1) {
        return false;
    }

    return true;
}
export const sendVerifyEmail = async (email: string, resend = false) => {
    const authStore = useAuthStore();
    const isValidEmail = /.+@.+\..+/.test(email)
    if (!isValidEmail) {
        notify({
            type: "error",
            title: "Invalid Email",
            text: "Please enter a valid email address",
        });
        return;
    }
    try {
        authStore.loading = true;
        await sendVerificationEmail({ email: email });
        if (resend) {
            notify({
                type: "success",
                title: "Email Resent",
                text: "Verification email has been resent",
            })
        }
    } catch (error: any) {
        notify({
            type: "error",
            title: "Error",
            text: error.message,
        });
    } finally {
        authStore.loading = false;
    }
}
export const sendResetVerifyEmail = async (email: string, resend = false) => {
    const authStore = useAuthStore();
    const isValidEmail = /.+@.+\..+/.test(email)
    if (!isValidEmail) {
        notify({
            type: "error",
            title: "Invalid Email",
            text: "Please enter a valid email address",
        });
        return;
    }
    try {
        authStore.loading = true;
        await requestResetPaswordEmail({ email: email });
        authStore.resetPasswordData.email = email
        if (resend) {
            notify({
                type: "success",
                title: "Email Resent",
                text: "Verification email has been resent",
            })
        }
    } catch (error: any) {
        notify({
            type: "error",
            title: "Error",
            text: error.message || error.details || error || "Something went wrong",
        });
    } finally {
        authStore.loading = false;
    }
}
export const handleConfirmPasswordReset = async (newPassword: string, id: string, token: string) => {
    const authStore = useAuthStore()
    try {
        authStore.loading = true
        const encrptKey = await getEncryptionKey()
        await requestPasswordResetConfirmation({
            uid: id,
            token: token,
            new_password: encryptToken(encrptKey.data.public_key, newPassword) as string
        })
        return true
    } catch (error: any) {
        notify({
            type: "error",
            title: "Error",
            text: error.message || error || "Something went wrong",
        });
    } finally {
        authStore.loading = false
    }
}

export const useSigninWithEmail = () => {
    const router = useRouter();
    const siginInWithEmail = async (data: { email: string, password: string }) => {
        const authStore = useAuthStore();
        const isValidEmail = /.+@.+\..+/.test(data.email)
        if (!isValidEmail) {
            notify({
                type: "error",
                title: "Invalid Email",
                text: "Please enter a valid email address",
            });
            return;
        }
        try {
            authStore.loading = true;
            const encrptKey = await getEncryptionKey()
            const res = await loginWithEmail({
                email: data.email,
                password: encryptToken(encrptKey.data.public_key, data.password) as string
            })
            if (res) {
                await authStore.login(res.data, true);
                return res
            }
        } catch (error: any) {
            notify({
                type: "error",
                title: "Error",
                text: error.message || error.detail || error || "Something went wrong",
            });
            if (error.toLowerCase() === "email has not been verified. please verify your email or reset your password") {
                setTimeout(async () => {
                    authStore.email = data.email;
                    await router.push("/email-verification");
                }, 1000);
            }

            if (error.code == "4001") {
                setTimeout(() => {
                    router.push("/sign-up");
                }, 1000);
            }
            if (error.code == "4012") {
                setTimeout(() => {
                    authStore.email = data.email;
                    router.push("/email-verification");
                }, 1000);
            }
        } finally {
            authStore.loading = false;
        }
    }
    return {
        siginInWithEmail
    }
}
