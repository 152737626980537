import { Schedule, states } from ".";
import { useAuthStore, useBusinessStore, useUtilsStore } from "../stores";
import {
  STAGING_BASE_API,
  INTERNAL_LIVE_BASE_API,
  PRODUCTION_BASE_API,
} from "./constants";
import { ref } from "vue";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";
import logger from "./logger";
import { notify } from "@kyvg/vue3-notification";
// import nigerianFlag from "/assets/Images/icons/flags/nigerian.svg";
// import kenyanFlag from "/assets/Images/icons/flags/kenya.svg";
import usFlag from "/assets/Images/icons/flags/usa.svg";
import ukFlag from "/assets/Images/icons/flags/uk.svg";
import canadaFlag from "/assets/Images/icons/flags/canada.svg";
import parsePhoneNumber from "libphonenumber-js";
import countries from "i18n-iso-countries";
// Register the locales you need (e.g., English and French)
import enLocale from "i18n-iso-countries/langs/en.json";

countries.registerLocale(enLocale);

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);

export const formatDateToString = (dateString: string) =>
  dayjs(dateString).format("Do MMMM, YYYY");
// export function formatPhoneNumber(phoneNumber: string) {
// }
interface FormattedPhoneNumberResult {
  formattedPhoneNumber: string;
  contactName: string;
  countryName: string;
  flag: string;
}
export function formatPhoneNumberWithCountryCode(phoneNumber: string) {
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  const pattern = /^(\+\d{3})(\d{3})(\d{4})(\d{3})$/;
  const formattedNumber = digitsOnly.replace(pattern, "$1 $2 $3 $4");
  return formattedNumber;
}
export function formatPhoneNumberWithSpaces(phoneNumber: string): string {
  const startsWithPlus234 = phoneNumber.startsWith("+234");
  const digitsOnly = phoneNumber.replace(/\D/g, "");
  const digitsWithoutCountryCode = startsWithPlus234
    ? digitsOnly.slice(3)
    : digitsOnly;
  const formatted = startsWithPlus234
    ? digitsWithoutCountryCode.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3")
    : digitsWithoutCountryCode.replace(/(\d{4})(\d{3})(\d{4})/, "$1 $2 $3");
  const finalNumber = startsWithPlus234 ? "+234 " + formatted : formatted;

  return finalNumber;
}

export function getInitials(fullName: string | null) {
  if (!fullName || /^\d+$/.test(fullName)) return "U";
  const words = fullName.trim().split(" ");
  const numWords = words.length;

  if (numWords === 1) {
    return fullName[0];
  } else if (numWords >= 3) {
    const firstInitial = words[0][0];
    const lastInitial = words[numWords - 1][0];
    return `${firstInitial}${lastInitial}`;
  } else {
    return words.map((word) => word[0]).join("");
  }
}

export function getAvatarColors() {
  const colors = [
    {
      color1: "#F65F7A",
      color2: "#EF889B",
    },
    {
      color1: "#2EC97F",
      color2: "#00e477c7",
    },
    {
      color1: "#EA61C4",
      color2: "#ffa5e6d1",
    },
    {
      color1: "#95BA48",
      color2: "#a8e130d1",
    },
    {
      color1: "#00C0EA",
      color2: "#5ADAF6",
    },
    {
      color1: "#715AFF",
      color2: "#BAAEFFD9",
    },
    {
      color1: "#11BEAC",
      color2: "#5BE7D9F0",
    },
    {
      color1: "#FF5A78",
      color2: "#FFB4C2E8",
    },
    {
      color1: "#EA61C4",
      color2: "#FFA5E6D1",
    },
  ];
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
}

export const validateMobile = (
  mobile_phone: string,
  dialCode: string = "+234"
) => {
  if (mobile_phone == null) {
    return "+234";
  }
  //remove spaces from input
  mobile_phone = mobile_phone.replace(/\s+/g, "");

  //remove any non-numeric input, but leave +.
  mobile_phone = mobile_phone.replace(/[^\d+]/g, "");

  //check phone number to make sure it starts with +234
  if (mobile_phone.substring(0, 1) !== "+") {
    if (mobile_phone.substring(0, 3) === "234")
      //if input is "234803444555", then just add a +
      mobile_phone = "+" + mobile_phone;
    else if (mobile_phone.substring(0, 1) === "0")
      //else add a dialcode
      mobile_phone = dialCode + mobile_phone.substring(1);
    else mobile_phone = dialCode + mobile_phone;
  }
  return mobile_phone;
};

export const getPhonePlaceholder = (country: string) => {
  interface PhonePlaceholders {
    [key: string]: string;
  }
  const placeholders: PhonePlaceholders = {
    "+234": "08XX XXX XXXX", // Nigeria (NGN)
    "+1": "2XX XXX XXXX", // United States (US)
    "+254": "7XX XXX XXX", // Kenya (KE)
  };

  return placeholders[country];
};

export const getPhoneInputLength = (country: string, zeroPrefix?: boolean) => {
  interface lengths {
    [key: string]: number;
  }
  const phoneLengths: lengths = {
    "+234": 12, // Nigeria (NGN)
    "+1": 12, // United States (US)
    "+254": 11, // Kenya (KE)
  };

  if (zeroPrefix && country === "+234") {
    return phoneLengths[country] + 1;
  }
  return phoneLengths[country];
};

export function validatePhoneNumber(number: string, dialCode: string): boolean {
  let regexPattern: RegExp | undefined;

  switch (dialCode) {
    case "+234": // Nigeria
      regexPattern = /^(0?[0-9][0-9]\d{8})$/;
      break;
    case "+1": // United States
      regexPattern = /^[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$/;
      break;
    case "+254": // Kenya
      regexPattern = /^([17]\d{8})$/;
      break;

    case "+44": // United Kingdom use this for now
      if (number.length > 7 && number.length <= 15) {
        return true;
      }
      return false;
    default:
      return false; // Invalid dial code
  }

  if (regexPattern) {
    return regexPattern.test(number);
  }

  return false; // Default case for any unexpected scenario
}

export const formatPhoneNumberWithCountryCodeAndCountry = (
  phone_number: string,
  contact_name: string = ""
) => {
  // Declare possible matches
  const matchFullMobile = phone_number.match(/^(\+234|)(\d{3})(\d{3})(\d{4})$/); // match +2348012878233
  const matchMobile = phone_number.match(/^(0|)(\d{3})(\d{3})(\d{4})$/); // match 08012878233 or 8012878233
  const matchFullLand = phone_number.match(/^(\+234)(\d{1})(\d{3})(\d{4})$/); // match +23417743211
  const matchLand = phone_number.match(/^(0|)(\d{1})(\d{3})(\d{4})$/); // match 017743211 or 17743211
  const matchLand2 = phone_number.match(/^(\d{3})(\d{4})$/); // match 7743211
  const matchFullUS = phone_number.match(/^(\+1)(\d{3})(\d{3})(\d{4})$/); // match +14083326784
  let countryName: string = "";
  let flag: string = "";
  // Format based on match
  if (matchFullMobile) {
    phone_number = `+234 ${matchFullMobile[2]} ${matchFullMobile[3]} ${matchFullMobile[4]}`;
    countryName = "NG";
    flag = "/assets/Images/icons/nigerian-flag.svg";
  } else if (matchMobile) {
    phone_number = `+234 ${matchMobile[2]} ${matchMobile[3]} ${matchMobile[4]}`;
    countryName = "NG";
    flag = "/assets/Images/icons/nigerian-flag.svg";
  } else if (matchFullLand) {
    phone_number = `+234 ${matchFullLand[2]} ${matchFullLand[3]} ${matchFullLand[4]}`;
    countryName = "NG";
    flag = "/assets/Images/icons/nigerian-flag.svg";
  } else if (matchLand) {
    phone_number = `+234 ${matchLand[2]} ${matchLand[3]} ${matchLand[4]}`;
    countryName = "NG";
    flag = "/assets/Images/icons/nigerian-flag.svg";
  } else if (matchLand2) {
    phone_number = `+234 1 ${matchLand2[1]} ${matchLand2[2]}`;
    countryName = "NG";
    flag = "/assets/Images/icons/nigerian-flag.svg";
  } else if (matchFullUS) {
    phone_number = `${matchFullUS[1]} (${matchFullUS[2]}) ${matchFullUS[3]} ${matchFullUS[4]}`;
    countryName = "US";
    flag = "/assets/Images/icons/us-flag.svg";
  }
  const result: FormattedPhoneNumberResult = {
    formattedPhoneNumber: phone_number,
    contactName: contact_name,
    countryName,
    flag,
  };
  // Add contact name if included
  return result;
};
export const formattedDateInMonthAndYear = (dateString: string) => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  return date.toLocaleDateString("en-GB", options);
};
export function formatDate(date: string | null) {
  if (date === null) {
    return "N/A";
  }
  // Create a Date object from the input string
  const dateObject = new Date(date);

  // Format the date using Intl.DateTimeFormat
  const formattedDateString = new Intl.DateTimeFormat("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  }).format(dateObject);

  return formattedDateString.replace(/ at /i, " ");
}

export function formatDateShort(date: string | null): string {
  if (date === null) {
    return "N/A";
  }

  // Create a Date object from the input string
  const dateObject = new Date(date);

  // Define options for formatting the date
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  // Format the date using Intl.DateTimeFormat
  const formattedDateString = new Intl.DateTimeFormat("en-GB", options).format(
    dateObject
  );

  // Insert a comma after the year manually
  const parts = formattedDateString.split(" ");
  return `${parts[0]} ${parts[1]}, ${parts[2]} ${parts[3]} ${parts[4]}`;
}

let timeOutid = ref(0);
export function debounce(func: Function, delay: number) {
  clearTimeout(timeOutid.value);
  timeOutid.value = setTimeout(() => {
    return func();
  }, delay) as any;
}
// export function calculateAccurateCallDuration(startDate: string, endDate: string): string | number {
//   const start = new Date(startDate);
//   const end = new Date(endDate);
//   const durationInMilliseconds = end.getTime() - start.getTime();
//   const durationInSeconds = durationInMilliseconds / 1000;
//   return durationInSeconds
// }
export function formatDateToNumber(date: string) {
  // converts to 2022-10-12 format
  const originalDate = new Date(date);

  const year = originalDate.getFullYear();
  const month = (originalDate.getMonth() + 1).toString().padStart(2, "0");
  const day = originalDate.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${day}`;
}
export function formatDateToMonthandDay(dateString: string) {
  const date = new Date(dateString.replace(" ", "T")); // Convert to ISO format

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const month = monthNames[date.getMonth()];
  const day = date.getDate();

  date.setHours(date.getHours() + 1);
  const hours = date.getHours();
  const minutes = date.getMinutes().toString().padStart(2, "0");

  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  return `${month} ${day}, ${formattedHours}:${minutes} ${ampm}`;
}

export function generateFilename(from_date: string, to_date: string): string {
  // genrate name for download

  const formatDate = (date: Date): string => {
    const options: Intl.DateTimeFormatOptions = {
      weekday: "long",
      day: "numeric",
      month: "long",
      year: "numeric",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const fromDateObj = new Date(from_date);
  const toDateObj = new Date(to_date);

  const formattedFrom = formatDate(fromDateObj);
  const formattedTo = formatDate(toDateObj);
  return `${formattedFrom} - ${formattedTo} report.csv`;
}

export function calculateCallDuration(durationInSeconds: number): string {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = Math.floor(durationInSeconds % 60);
  const formattedDuration = `${hours > 0 ? hours + " hr " : ""}${minutes > 0 ? minutes + " min " : ""
    }${seconds} seconds`;
  return formattedDuration.trim();
}
export function formatDurationInTimerFormat(durationInSeconds: number): string {
  const hours = Math.floor(durationInSeconds / 3600);
  const minutes = Math.floor((durationInSeconds % 3600) / 60);
  const seconds = durationInSeconds % 60;

  const formattedDuration = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  return formattedDuration;
}
export const formatAudioDuration = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
};
export function convertToFormattedTime(
  isoDateString: string | null | undefined
): string {
  if (!isoDateString) {
    // Handle the case where date is null
    return "N/A"; // or any other default value or message
  }
  const date = new Date(isoDateString);
  const options: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };
  return date.toLocaleTimeString(undefined, options);
}

export function copyText(text: string) {
  navigator.clipboard.writeText(text);
}
export function getCurrentFromUrl(link: string): string | null {
  const urlSearchParams = new URLSearchParams(link);
  const page = urlSearchParams.get("page");
  return page;
}
export function truncateString(input: string, maxLength: number): string {
  if (!input) return "";
  if (input.length <= maxLength) {
    return input;
  } else {
    return input.substring(0, maxLength) + "...";
  }
}

export function formatMoney(
  amountString: string,
  includeTrailingZeros?: boolean
) {
  // Convert the string to a number for formatting
  const amount = parseFloat(amountString);

  // Check if the conversion is successful
  if (isNaN(amount)) {
    // Handle invalid input gracefully
    return "Invalid amount";
  }

  // Format the number with or without trailing zeros based on the boolean flag
  const formattedAmount = includeTrailingZeros
    ? amount.toFixed(2)
    : amount.toLocaleString(undefined, {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    });

  // Add commas for thousands separator
  return formattedAmount
    .replace(/\d(?=(\d{3})+\.)/g, "$&,")
    .replace(/\.00$/, "");
}

export function emailIsValid(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}
export const userJustJoined = (first_name: string, last_name: string) => {
  function _containsNumbers(str: string) {
    return /\d/.test(str);
  }
  if (_containsNumbers(first_name) && _containsNumbers(last_name)) return true;
  return false;
};
export const switchBaseURL = async (key: string) => {
  switch (key) {
    case "staging":
      localStorage.setItem("baseUrl", STAGING_BASE_API);
      break;
    case "internal_live":
      localStorage.setItem("baseUrl", INTERNAL_LIVE_BASE_API);
      break;
    case "production":
      localStorage.setItem("baseUrl", PRODUCTION_BASE_API);
      break;
    default:
      break;
  }
  const authStore = useAuthStore();
  const utilStore = useUtilsStore();
  if (utilStore.qaBaseUrl) utilStore.qaBaseUrl = ""; // clear the qa code
  if (authStore.isAuthenticated) {
    await authStore.logout();
  }
  location.reload();
};
// logout the user but still switch the base url
export const safeSwitchBaseURL = async (key: string) => {
  switch (key) {
    case "staging":
      localStorage.setItem("baseUrl", STAGING_BASE_API);
      break;
    case "internal_live":
      localStorage.setItem("baseUrl", INTERNAL_LIVE_BASE_API);
      break;
    case "production":
      localStorage.setItem("baseUrl", PRODUCTION_BASE_API);
      break;
    default:
      break;
  }
  const utilStore = useUtilsStore();
  if (utilStore.qaBaseUrl) utilStore.qaBaseUrl = ""; // clear the qa code
  location.reload();
};
export function getUniqueTimeAndDays(schedule: Schedule) {
  const uniqueTimes = new Set();

  for (const day in schedule) {
    const time = schedule[day];
    if (!uniqueTimes.has(time)) {
      uniqueTimes.add(time);
      // Abbreviating days ie Monday = Mon
    }
  }
  const uniqueDays: string[] = Object.keys(schedule);

  const uniqueTime = Array.from(uniqueTimes).join("-");

  const uniqueDayString = uniqueDays
    .map((day) => day.toLowerCase().slice(0, 3))
    .join(", ");

  return { time: uniqueTime, day: uniqueDayString };
}
export function getTimeFrameByValue(value: string) {
  const currentDate = new Date();
  let startDate, endDate;

  switch (value) {
    case "past_week":
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 7);
      endDate = currentDate;
      break;
    case "yesterday":
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate() - 1);
      endDate = new Date(currentDate);
      endDate.setDate(currentDate.getDate() - 1);
      break;
    case "today":
      startDate = new Date(currentDate);
      startDate.setDate(currentDate.getDate());
      endDate = new Date(currentDate);
      endDate = new Date(startDate.getTime() + 24 * 60 * 60 * 1000);
      break;
    case "past_month":
      startDate = new Date(currentDate);
      startDate.setMonth(currentDate.getMonth() - 1);
      endDate = currentDate;
      break;
    case "past_year":
      startDate = new Date(currentDate);
      startDate.setFullYear(currentDate.getFullYear() - 1);
      endDate = currentDate;
      break;
    default:
      const [month, year] = value.split("_");
      // get the date range of start and end month
      startDate = dayjs(`${year}-${month}-01`).startOf("month").toDate();
      startDate.setHours(1, 0, 0, 0);
      endDate = dayjs(`${year}-${month}-01`).endOf("month").toDate();
      break;
  }

  return { startDate, endDate };
}
export function obfuscatePhoneNumber(phoneNumber: string) {
  if (phoneNumber.length < 6) {
    return phoneNumber;
  }
  const prefix = phoneNumber.substring(0, 3);
  const obfuscatedPart = ".".repeat(phoneNumber.length - 5);
  const suffix = phoneNumber.substring(phoneNumber.length - 3);
  return `${prefix}${obfuscatedPart}${suffix}`;
}
export function formatPhoneNumberPresentation(phoneNumber: string) {
  // Using regular expression to add spaces
  if (phoneNumber)
    return phoneNumber.replace(/(\d{4})(\d{4})(\d{3})/, "$1 $2 $3");
  else return "";
}

export function formatDateforLeaderboard(dateString: string | undefined) {
  if (!dateString) {
    return ["N/A"];
  }
  const date = new Date(dateString ?? "");

  // Get day of the week
  const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const dayOfWeek = daysOfWeek[date.getDay()];

  // Get month
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const month = months[date.getMonth()];

  // Get hour and minute
  let hour = date.getHours();
  const minute = date.getMinutes();

  // Convert hour to 12-hour format and determine AM/PM
  const amPm = hour >= 12 ? "PM" : "AM";
  hour = hour % 12 || 12; // Convert hour 0 to 12

  // Format the date part of the output
  const formattedDate = `${dayOfWeek}, ${month} ${date.getDate()}`;

  // Format the time part of the output
  const formattedTime = `${hour}:${minute.toString().padStart(2, "0")} ${amPm}`;

  // Return an array with date and time separated
  return [formattedDate, formattedTime];
}

export const PlanBenefits: any = {
  "Standard Plan": [
    "3 Team Members (Additional users cost 2000/Month)",
    "All Standard Features (Caller Greeting, Call Routing, Call Monitoring, etc)",
  ],
  "Growth Plan": [
    "5 Team Members (Additional users cost 2000/Month)",
    "All Standard Features (Caller Greeting, Call Routing, Call Monitoring, etc)",
    "Integrations",
    "Website Live Call Add-on",
  ],
  "International Plan": [
    "5 Team Members (Additional users cost 2000/Month)",
    "All Standard Features (Caller Greeting, Call Routing, Call Monitoring, etc)",
    "Integrations",
    "Website Live Call Add-on",
    "US Phone number",
  ],
  International: [
    "5 Team Members (Additional users cost 2000/Month)",
    "All Standard Features (Caller Greeting, Call Routing, Call Monitoring, etc)",
    "Integrations",
    "Website Live Call Add-on",
    "US Phone number",
  ],
  solo: [
    "Only one member (No additional user)",
    "Customer would need to upgrade plan for additional users",
  ],
  "Solo Plan": [
    "Only one member (No additional user)",
    "Customer would need to upgrade plan for additional users",
  ],
};
export const isEmptyObject = (obj: {} | any) => Object.keys(obj).length === 0;

export const isEmptyArray = (arr: []) => {
  if (!arr) {
    return false;
  }
  arr.length === 0;
};
export function snakeToCamelCase(str: string) {
  if (!str) return "";
  return str.replace(/_/g, " ").replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase();
  });
}
export const BusinessRoles: { title: string; options: string[] } = {
  title: "What best describes you?",
  options: [
    "Business Owner",
    "Marketing",
    "Sales",
    "Human Resources",
    "IT Operations",
    "Customer Service/Support",
    "Others",
  ],
};

export const HandleBusinessCalls: { title: string; options: string[] } = {
  title: "How do you currently handle business calls?",
  options: [
    "We use regular mobile line(Airtel, MTN e.t.c)",
    "We use a call center solution",
  ],
};

export const ReasonForGettingPressoneCallCenter: {
  title: string;
  options: string[];
} = {
  title: "What is the #1 reason you are getting PressOne?",
  options: [
    "Current Solution is too expensive",
    "Current Solution does not work very well",
    "Current solution lacks some key features",
    "I don't know",
  ],
};

export const ReasonForGettingPressoneMobile: {
  title: string;
  options: string[];
} = {
  title: "What is the #1 reason you are getting PressOne?",
  options: [
    "I want to improve my brand with professional greeting",
    "I want to see all the calls everyone is making",
    "I want access to audio recordings of every call",
  ],
};

export const WhenToStartUsing: {
  title: string;
  options: string[];
} = {
  title: "When would you like to start using PressOne?",
  options: [
    "Immediately",
    "In 2 to 3 weeks",
    "Not sure, depends on something else.",
  ],
};

export const NumberOfCalls: {
  title: string;
  options: string[];
} = {
  title: "What is your number of weekly calls?",
  options: ["0 - 100", "101 - 200", "201 - 500", "501 - 1,000"],
};

export const ServicesSold: {
  title: string;
  options: string[];
} = {
  title: "What product or service do you sell?",
  options: [
    "Business Consulting",
    "Software Development",
    "Marketing Strategy",
    "Legal Services",
    "Others",
  ],
};

export const HandleCompanyCalls: {
  title: string;
  options: string[];
} = {
  title: "How do you currently make calls for your company?",
  options: ["I use my personal line", "My company gave me an official phone"],
};

export const AirtimeSource: {
  title: string;
  options: string[];
} = {
  title: "How do you get airtime to make calls?",
  options: [
    "I buy my airtime and the company refunds me",
    "The company buys into my phone periodically",
    "I use a post-paid line",
  ],
};

export const PeriodicReports: {
  title: string;
  options: string[];
} = {
  title: "Do you need to send periodic reports on calls to your manager?",
  options: [
    "Yes, I send reports daily/weekly",
    "Yes, I send reports monthly",
    "No, I do not send reports.",
  ],
};

export const PreferredChoices: {
  title: string;
  options: string[];
} = {
  title: "Which of these would your rather have?",
  options: [
    "Calls are automatically answered by voicemail after work hours",
    "You never need to send reports anymore",
    "You have an easy way to keep track of calls you made",
    "You don’t need to use your personal number for business calls anymore",
  ],
};

export function formatDuration(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const formattedHours = String(hours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}
export function useCopyToClipboard() {
  const isCopied = ref(false);
  const copyToClipboard = async (data: string) => {
    try {
      await navigator.clipboard.writeText(data);
      isCopied.value = true;
      setTimeout(() => {
        isCopied.value = false;
      }, 2000); // Reset the isCopied state after 2 seconds
    } catch (error) {
      logger.error(error, "Failed to copy to clipboard:");
      notify({
        text: "Failed to copy to clipboard",
        type: "error",
      });
      isCopied.value = false;
    }
  };

  return {
    isCopied,
    copyToClipboard,
  };
}
// Convert index number to words
export const numberToWords = (num: number) => {
  const words = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
  ];
  return words[num - 1];
};
export const isNigerianNumber = (phoneNumber: string) => {
  const nigerianPattern = /^(?:\+234|234|0)?(70|80|81|90|91|20)\d{8}$/;

  return nigerianPattern.test(phoneNumber);
};
export function formatNigerianNumber(phoneNumber: string) {
  if (phoneNumber.startsWith("0")) {
    return "+234" + phoneNumber.slice(1);
  } else if (phoneNumber.startsWith("234")) {
    return "+" + phoneNumber;
  }
  return phoneNumber;
}
export const embedToThumbnail = (embedUrl: string) => {
  const regex =
    /(?:\/embed\/|\/watch\?v=|\/vi\/|\/v\/|youtu\.be\/|\/vi\/)([^#&?]*).*/;
  const match = embedUrl?.match(regex);
  const videoId = match && match[1].length === 11 ? match[1] : null;
  if (videoId) {
    return `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`;
  } else {
    return "https://img.youtube.com/vi/isqEP4zAuao/hqdefault.jpg";
  }
};

export function transformMessage(message: string): string {
  // Match the part of the message after "ID: " and before the period.
  const match = message.match(/ID: (.*?)\./);
  if (match) {
    // Replace ", " with "/" and return the formatted message.
    const fields = match[1].replace(/, /g, "/");
    return `NIN Verification failed. Your ${fields} does not match NIN records. Please try again with correct data.`;
  }
  return message; // Fallback in case of unexpected format
}

export const phoneNumberRules = (value: string, dialCode: string) => {
  let regexPattern: RegExp | undefined;

  switch (dialCode) {
    case "+234": // Nigeria
      regexPattern = /^(0?[0-9][0-9]\d{8})$/;
      break;
    case "+1": // United States
      regexPattern = /^[2-9][0-9]{2}[2-9][0-9]{2}[0-9]{4}$/;
      break;
    case "+254": // Kenya
      regexPattern = /^([17]\d{8})$/;
      break;
    default:
      regexPattern = /^(0?[0-9][0-9]\d{8})$/; // Invalid dial code
  }
  if (regexPattern.test(value)) {
    return !!value;
  } else return "Phone number must be a valid phone number";
};

export const getHangupEvent = (timeString: string) => {
  const [hours, minutes, seconds] = timeString.split(":").map(Number);
  const totalSeconds = hours * 3600 + minutes * 60 + seconds;
  return totalSeconds > 0;
};

export const planText = (planTitle: string) => {
  const lowercaseTitle = planTitle.toLowerCase();
  if (lowercaseTitle.includes("solo")) {
    return "Designed for solo professionals, manage your calls efficiently and deliver a superior experience to your customers.";
  } else if (lowercaseTitle.includes("international")) {
    return "Designed for businesses that want an international presence, manage your team and deliver a superior call experience to customers.";
  } else {
    return "Designed for businesses with multiple staff,manage your team and deliver a superior call experience to customers.";
  }
};

export const objectToQueryString = (
  params: Record<string, string | number | boolean>
) => {
  if (!params) {
    return;
  }
  const queryString = Object.keys(params)
    .map(
      (key) => encodeURIComponent(key) + "=" + encodeURIComponent(params[key])
    )
    .join("&");

  // Add '?' only if queryString is not empty
  return queryString ? `?${queryString}` : ("" as string);
};

export const isPressoneAccount = () => {
  const businessStore = useBusinessStore()
  return businessStore.currentBusinessId === 2163
}

export const isPressoneAccountUnAuthenticated = () => {
  return JSON.parse(localStorage.getItem("isPressOneAccount") || "false")
}
export function formatPhoneNumber(phoneNumber: string): string {
  // Remove any non-digit characters except for the plus sign
  const cleaned = phoneNumber.replace(/[^\d+]/g, "");

  if (cleaned.startsWith("0")) {
    const areaCode = cleaned.slice(0, 2).replace(/^0/, ""); // Remove leading '0'
    const subscriberNumber = cleaned.slice(2); // Remaining part of the number

    // Format to international standard
    return `+234 ${areaCode} ${subscriberNumber}`;
  }

  // Format for US: +1 (XXX) XXX-XXXX
  if (cleaned.startsWith("+1") && cleaned.length === 12) {
    return `${cleaned.slice(0, 2)} (${cleaned.slice(2, 5)}) ${cleaned.slice(
      5,
      8
    )}-${cleaned.slice(8)}`;
  }

  const formattedNumber = parsePhoneNumber(phoneNumber)!.formatInternational();
  return formattedNumber;
}

export const getLocation = (
  state_code: string | null,
  country_code: string
) => {
  let state = "";
  if (state_code && states[country_code]) {
    const stateObject = states[country_code].find(
      (state: any) => state?.stateCode == state_code
    );
    state = stateObject?.stateName ? stateObject?.stateName + ", " : "";
  }
  return `${state ? state : ""}${country_code !== "GB" ? country_code : "UK"}`;
};

export const formatDecimal = (num: number) => {
  return num.toLocaleString("en-US", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const selectedFlag = (country: string) => {
  const countries = [
    // { flag: nigerianFlag, country: "Nigeria", countryCode: "NG" },
    // { flag: kenyanFlag, country: "Kenya", countryCode: "KE" },
    { flag: usFlag, country: "United States", countryCode: "US" },
    { flag: ukFlag, country: "United Kingdom", countryCode: "GB" },
    { flag: canadaFlag, country: "Canada", countryCode: "CA" },
  ];
  return countries.find(
    (item: any) => item.country == country || item.countryCode == country
  )?.flag;
};

export const getCountryName = (countryCode: string) => {
  return countries.getName(countryCode, "en");
};

export function createComboPlan(plans: any[]) {
  if (plans.length < 2) {
    return plans;
  }

  let [plan1, plan2] = plans;

  // Ensure plan2 is the one with "International" in its title
  if (!plan2.title.includes("International")) {
    [plan1, plan2] = [plan2, plan1];
  }

  // Create the combo plan with specified properties
  const comboPlan = {
    standard_cost: plan1.standard_cost + plan2.standard_cost,
    number_region: plan2.number_region,
    id: plan2.id, // Use the ID from the "International" plan
    title: "Phone Number + Address",
    standard_cost_currency: plan2.standard_cost_currency,
    term: plan2.term,
    international_number_enabled: plan2.international_number_enabled,
    extensions_enabled: plan2.extensions_enabled,
    extensions_limit: plan2.extensions_limit,
    plan_type: "number_and_addon",
    payment_type: plan2.payment_type,
    description: null,
    addon_type: plan2.addon_type,
    addon_id: plan1.id,
  };

  // Return a new array with the combo plan added
  return [...plans, comboPlan];
}
