import { fetchWrapper } from "../fetch-wrapper";
import { BASE_API, IS_PROD } from "../constants";
import { useBusinessStore } from "../../stores";

interface Payload {
  question_one?: string;
  answer_one?: string;
  question_two?: string | null;
  answer_two?: string | null;
  question_three?: string | null;
  answer_three?: string | null;
  question_four?: string | null;
  answer_four?: string | null;
}
export interface engagementPayload {
  data: {
    question: string;
    answer: string;
  }[];
  is_for_new_business: boolean;
  form_type: string;
}
export const getActiveBusinessId = () => {
  const businessStore = useBusinessStore();
  return businessStore.currentBusinessId;
};
export const postCustomerEngagement = async (payload: Payload) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/user/deprecated-engagement-form/`,
    payload
  );
};
export const newPostCustomerEngagement = async (payload: engagementPayload) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/engagement-form/`,
    payload
  );
};

export const agentFeedback = async (payload: {
  rating: number;
  feedback: string;
}) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/survey/`,
    payload
  );
};

export const submitCustomerDotIODetails = async (payload: any) => {
  let url;
  if (IS_PROD) {
    url = "https://api-eu.customer.io/v1/webhook/b6c4df1c1c71e751";
  } else {
    url = " https://api-eu.customer.io/v1/webhook/f5fa0339bf620571";
  }
  return await fetchWrapper.public_post(`${url}`, payload, true);
};
