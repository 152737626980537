import {
  cancelAddressRenewal,
  fetchGlobalAddress,
  fetchPurchasedAddress,
} from "./queries/add-ons/address-numbers";
import { objectToQueryString } from "./utils";
import { cleanPayload } from "./callQueue";
import { notify } from "@kyvg/vue3-notification";
import { useNumberAddressAddOnStore } from "../stores/add-ons/number-address.store";
import { storeToRefs } from "pinia";

const pageSize = 100;

export const useAddress = () => {
  const numberAddressAddOnStore = useNumberAddressAddOnStore();

  const { selectedAddress } = storeToRefs(numberAddressAddOnStore);

  const handleFetchAddress = async (
    payload: Record<string, string | number | boolean | null> = {}
  ) => {
    let query = {
      // state: stateCode.value,
      page_size: pageSize,
      ...payload,
    };
    // to remove empty values and keys
    query = cleanPayload(query);

    const queryString = objectToQueryString(query) as string;
    try {
      const response = await fetchGlobalAddress(queryString);
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  const handleFetchBusinessAddresses = async (
    payload: Record<string, string | number | boolean | null> = {}
  ) => {
    let query = {
      // state: stateCode.value,
      page_size: 50,
      ...payload,
    };
    // to remove empty values and keys
    query = cleanPayload(query);

    const queryString = objectToQueryString(query) as string;
    try {
      const response = await fetchPurchasedAddress(queryString);
      return response?.data?.data;
    } catch (error) {
      console.log(error);
    }
  };

  const handleCancelRenewal = async (id: string) => {
    try {
      const response = await cancelAddressRenewal(id);
      notify({
        type: "success",
        text: "Address renewal cancelled",
      });
      return response?.data?.data;
    } catch (error) {
      notify({
        type: "error",
        text: "Error occured while cancelling renewal",
      });
    }
  };

  return {
    handleFetchAddress,
    selectedAddress,
    handleFetchBusinessAddresses,
    handleCancelRenewal,
  };
};
