export const states = {
  US: [
    { stateName: "All States", stateCode:null },
    { stateName: "Alabama", stateCode: "AL" },
    { stateName: "Alaska", stateCode: "AK" },
    { stateName: "American Samoa", stateCode: "AS" },
    { stateName: "Arizona", stateCode: "AZ" },
    { stateName: "Arkansas", stateCode: "AR" },
    // { stateName: "Armed Forces Americas", stateCode: "AA" },
    // { stateName: "Armed Forces Europe", stateCode: "AE" },
    // { stateName: "Armed Forces Pacific", stateCode: "AP" },
    { stateName: "California", stateCode: "CA" },
    { stateName: "Colorado", stateCode: "CO" },
    { stateName: "Connecticut", stateCode: "CT" },
    { stateName: "Delaware", stateCode: "DE" },
    { stateName: "District of Columbia", stateCode: "DC" },
    { stateName: "Florida", stateCode: "FL" },
    { stateName: "Georgia", stateCode: "GA" },
    { stateName: "Guam", stateCode: "GU" },
    { stateName: "Hawaii", stateCode: "HI" },
    { stateName: "Idaho", stateCode: "ID" },
    { stateName: "Illinois", stateCode: "IL" },
    { stateName: "Indiana", stateCode: "IN" },
    { stateName: "Iowa", stateCode: "IA" },
    { stateName: "Kansas", stateCode: "KS" },
    { stateName: "Kentucky", stateCode: "KY" },
    { stateName: "Louisiana", stateCode: "LA" },
    { stateName: "Maine", stateCode: "ME" },
    { stateName: "Marshall Islands", stateCode: "MH" },
    { stateName: "Maryland", stateCode: "MD" },
    { stateName: "Massachusetts", stateCode: "MA" },
    { stateName: "Michigan", stateCode: "MI" },
    { stateName: "Minnesota", stateCode: "MN" },
    { stateName: "Mississippi", stateCode: "MS" },
    { stateName: "Missouri", stateCode: "MO" },
    { stateName: "Montana", stateCode: "MT" },
    { stateName: "Nebraska", stateCode: "NE" },
    { stateName: "Nevada", stateCode: "NV" },
    { stateName: "New Hampshire", stateCode: "NH" },
    { stateName: "New Jersey", stateCode: "NJ" },
    { stateName: "New Mexico", stateCode: "NM" },
    { stateName: "New York", stateCode: "NY" },
    { stateName: "North Carolina", stateCode: "NC" },
    { stateName: "North Dakota", stateCode: "ND" },
    { stateName: "Northern Mariana Islands", stateCode: "MP" },
    { stateName: "Ohio", stateCode: "OH" },
    { stateName: "Oklahoma", stateCode: "OK" },
    { stateName: "Oregon", stateCode: "OR" },
    { stateName: "Pennsylvania", stateCode: "PA" },
    { stateName: "Puerto Rico", stateCode: "PR" },
    { stateName: "Rhode Island", stateCode: "RI" },
    { stateName: "South Carolina", stateCode: "SC" },
    { stateName: "South Dakota", stateCode: "SD" },
    { stateName: "Tennessee", stateCode: "TN" },
    { stateName: "Texas", stateCode: "TX" },
    { stateName: "U.S. Virgin Islands", stateCode: "VI" },
    { stateName: "Utah", stateCode: "UT" },
    { stateName: "Vermont", stateCode: "VT" },
    { stateName: "Virginia", stateCode: "VA" },
    { stateName: "Washington", stateCode: "WA" },
    { stateName: "West Virginia", stateCode: "WV" },
    { stateName: "Wisconsin", stateCode: "WI" },
    { stateName: "Wyoming", stateCode: "WY" },
  ],
  CA: [
    { stateName: "All States", stateCode:null },
    { stateName: "Alberta", stateCode: "AB" },
    { stateName: "British Columbia", stateCode: "BC" },
    { stateName: "Manitoba", stateCode: "MB" },
    { stateName: "New Brunswick", stateCode: "NB" },
    { stateName: "Newfoundland and Labrador", stateCode: "NL" },
    { stateName: "Nova Scotia", stateCode: "NS" },
    { stateName: "Ontario", stateCode: "ON" },
    { stateName: "Prince Edward Island", stateCode: "PE" },
    { stateName: "Quebec", stateCode: "QC" },
    { stateName: "Saskatchewan", stateCode: "SK" },
    { stateName: "Northwest Territories", stateCode: "NT" },
    { stateName: "Nunavut", stateCode: "NU" },
    { stateName: "Yukon", stateCode: "YT" },
  ],
} as any;
