import ukFlag from '/assets/Images/icons/flags/uk.svg';
import canadaFlag from '/assets/Images/icons/flags/canada.svg';
import usFlag from '/assets/Images/icons/flags/usa.svg';
import kenyanFlag from '/assets/Images/icons/flags/kenya.svg';
import nigerianFlag from '/assets/Images/icons/flags/nigerian.svg';

import { defineStore } from "pinia";
import {
    allQuestionsGoGlobal,
    InviteTeamMemberPayload,
    PINIA_PERSIST_OPTIONS,
    sendVerificationEmail,
} from "../helpers";
import { useNumberStore } from "./numbers.store";
import { useBusinessStore } from "./businesses.store";
import { useUsersStore } from "./users.store";
import router from "../router";

interface Question {
    id: string;
    title: string;
    options: string[];
    rowType: string;
}

interface UtilsState {
    pendingSetupPayload: any;
    isActivatingNumber: boolean;
    isActivated: boolean;
    userSignUpSource: string;
    isRecommendedPlanMode?: boolean;
    signUpSource?: string;
    signInSource?: string;
    affiliateLinkCode?: string;
    affiliateLinkRef?: string;
    affiliateLinkId?: string;
    signUpFormData: {
        first_name: string;
        last_name: string;
        personal_email: string;
        mobile: string;
        id: string | null;
    };
    existingBusiness: any;
    newBusinessObject: any;
    showFullScreen: boolean;
    pendingMember?: InviteTeamMemberPayload;
    redirectToVerification?: boolean;
    youtubeVideos: null | any;
    mobileDetectionDone: boolean;
    redirectToVoiceOtp?: boolean;
    productsNotification: boolean;
    previousRoutePath?: string;
    isDeveloper: boolean;
    rerouteFrom: string;
    hasReloaded: boolean;
    //   allQuestions: Question[];
    allAgentsQuestions: Question[];
    goGlobalPayment: boolean;
    isAddressSelected: boolean;
    goGlobalcountry: string;
    isNewBusiness: boolean;
    totalCalls: number; // stores the total number of calls made by a user
    showNINModal: boolean;
    madeFirstCall: boolean;
    showVerifyModalForCall: boolean;
    showVerifyEmailModal: boolean;
    emailVerificationLater: boolean;
    successfullyVerified: boolean;
    showSurveyModal: boolean;
    showSurveyCard: boolean;
    intervalId: number | null;
    qaBaseUrl: string;
    isSingleGoGlobalUser: boolean;
    currentProvisionedNumberId: string;
    autoDial: boolean;
    showFeedBackModal: boolean;
    showPostOnboarding: boolean;
    useCaseList: {
        name: string
        value: string
    }[],
    countryDialingInfo: {
        countryName: string; countryFlag: string, countryCode: string, dialCode: string
    }[]
}

export const useUtilsStore = defineStore({
    id: "utils",
    state: (): UtilsState => ({
        pendingSetupPayload: [],
        isActivatingNumber: false,
        isActivated: false,
        autoDial: true,
        currentProvisionedNumberId: "",
        userSignUpSource: "", // get the sign up source from route query param eg sterling
        isRecommendedPlanMode: false,
        affiliateLinkCode: "",
        affiliateLinkRef: "",
        affiliateLinkId: "",
        signInSource: "",
        signUpFormData: {
            first_name: "",
            last_name: "",
            personal_email: "",
            mobile: "",
            id: null,
        },
        existingBusiness: null, // stores existing business
        newBusinessObject: null, // stores new business when number is purchased
        showFullScreen: false,
        youtubeVideos: null,
        mobileDetectionDone: false,
        redirectToVoiceOtp: false,
        productsNotification: true,
        previousRoutePath: "",
        isDeveloper: true,
        rerouteFrom: "",
        hasReloaded: false,
        goGlobalPayment: false,
        goGlobalcountry: "",
        isAddressSelected: false,
        allAgentsQuestions: [
            {
                id: "HandleCompanyCalls",
                title: "How do you currently make calls for your company?",
                options: [
                    "I use my personal line",
                    "My company gave me an official phone",
                ],
                rowType: "landscape",
            },
            {
                id: "AirtimeSource",
                title: "How do you get airtime to make calls?",
                options: [
                    "I buy my airtime and the company refunds me",
                    "The company buys into my phone periodically",
                    "I use a post-paid line",
                ],
                rowType: "fourBox",
            },
            {
                id: "PeriodicReports",
                title: "Do you need to send periodic reports on calls to your manager?",
                options: [
                    "Yes, I send reports daily/weekly",
                    "Yes, I send reports monthly",
                    "No, I do not send reports.",
                ],
                rowType: "fourBox",
            },
            {
                id: "PreferredChoices",
                title: "Which of these would your rather have?",
                options: [
                    "Calls are automatically answered by voicemail after work hours",
                    "You never need to send reports anymore",
                    "You have an easy way to keep track of calls you made",
                    "You don’t need to use your personal number for business calls anymore",
                ],
                rowType: "landscape",
            },
        ],
        isNewBusiness: false, // check where the engagement is being filled from. False from popup
        totalCalls: 0,
        showNINModal: false,
        madeFirstCall: false,
        showVerifyModalForCall: false,
        successfullyVerified: false,
        showSurveyModal: false,
        showSurveyCard: false,
        intervalId: null,
        qaBaseUrl: "",
        showVerifyEmailModal: false,
        emailVerificationLater: false,
        isSingleGoGlobalUser: false,
        showFeedBackModal: false,
        showPostOnboarding: false,
        useCaseList: [
            {
                name: "Coach and track my sales team",
                value: "sales",
            },
            {
                name: "Improve my customer service team",
                value: "service",
            },
            {
                name: "Improve my business brand with professional greetings.",
                value: "branding",
            },
            {
                name: "Others",
                value: " ",
            },
        ],
        countryDialingInfo: [
            { countryName: 'Nigeria', countryFlag: nigerianFlag, countryCode: "NG", dialCode: "+234" },
            { countryName: 'Kenya', countryFlag: kenyanFlag, countryCode: "KE", dialCode: "+254" },
            { countryName: 'USA', countryFlag: usFlag, countryCode: "US", dialCode: "+1" },
            { countryName: 'Canada', countryFlag: canadaFlag, countryCode: "CA", dialCode: "+1" },
            { countryName: 'UK', countryFlag: ukFlag, countryCode: "GB", dialCode: "+44" },
        ]
    }),
    getters: {
        getShowBanner() {
            const numberStore = useNumberStore();
            const businessStore = useBusinessStore();
            const userStore = useUsersStore();

            if (
                userStore.currentUserBusinessLevel?.free_trial_data?.is_free_trial_user
            )
                return false;
            if (!numberStore.numbers || numberStore.numbers.length < 1) {
                return true;
            } else if (
                !businessStore.activeProfile?.business.verified &&
                this.madeFirstCall
            ) {
                return true;
            }

            if (
                numberStore.subscriptionStatus === "past_due" ||
                numberStore.subscriptionStatus === "pending"
            ) {
                return true;
            }
            if (
                userStore.getAgentRole.can_manage_billing &&
                businessStore.activeProfile.business.billing_requires_attention &&
                (userStore.user_role === "manager" || userStore.user_role === "owner")) {
                return true
            }
            if (userStore.currentUserBusinessLevel && !userStore.currentUserBusinessLevel?.email_verified) {
                return true;
            }
            if (!businessStore.activeBusiness?.business?.has_provisioned_first_number) {
                return true
            }
            return false;
        },
        getIsAutoDial(): boolean {
            return this.autoDial;
        },
        allQuestions(): Question[] {
            return useBusinessStore().goGlobalFirstSignIn
                ? allQuestionsGoGlobal
                : [
                    {
                        id: "HandleBusinessCalls",
                        title: "How do you currently handle business calls?",
                        options: [
                            "We use regular mobile line(Airtel, MTN e.t.c)",
                            "We use a call management solution",
                        ],
                        rowType: "landscape",
                    },

                    {
                        id: "NumberOfUsers",
                        title: "How many people will use this number?",
                        options: ["Just me", "1 - 5", "6 - 15", "More than 15"],
                        rowType: "box",
                    },
                    {
                        id: "ServicesSold",
                        title: "What product or service do you sell?",
                        options: [
                            "E-Commerce",
                            "Financial Services",
                            "Logistics",
                            "Real Estate",
                            "Human Resources",
                            "Education",
                            "Healthcare",
                            "Software",
                            "Others",
                        ],
                        rowType: "box",
                    },
                    {
                        id: "NumberOfCalls",
                        title: "What is your number of weekly calls?",
                        options: [
                            "0 - 100",
                            "101 - 200",
                            "201 - 500",
                            "501 - 1,000",
                            "1,001 - 5,000",
                            "Above 5,000",
                        ],
                        rowType: "box",
                    },
                ];
        },
        // use this to determine what will be displayed in the 10 secs popup modal
        // For exisiting user they should see the feedback survey while new users see the post onboarding prompt

        displayFeedbackSurvey(): boolean {
            const userStore = useUsersStore()
            return !userStore.currentUserBusinessLevel?.has_completed_feedback
        }

    },
    actions: {
        setSetUpPayload(payload: any) {
            this.pendingSetupPayload = payload;
        },
        handleClick() {
            router.push("/managers/profile-update");
            this.redirectToVerification = true;
            this.showNINModal = true;
            this.showVerifyModalForCall = false;
            setTimeout(() => {
                this.redirectToVerification = false;
            }, 2000);
        },
        async openVerifyEmailModal() {
            const userStore = useUsersStore();
            await sendVerificationEmail({ email: userStore.currentUser?.personal_email });
            this.showVerifyEmailModal = true;
        },
        runSurveyCheck() {
            const businessStore = useBusinessStore();
            const hasSubmittedSurvey =
                businessStore.activeBusiness?.has_submitted_weekly_survey;

            const now = new Date();
            // Check if it's exactly 12:00 AM (midnight)
            if (!hasSubmittedSurvey) {
                if (now.getHours() === 10 && now.getMinutes() === 0) {
                    this.showSurveyModal = true;
                    if (this.intervalId !== null) {
                        // clear interval after modal popup
                        clearInterval(this.intervalId);
                        this.intervalId = null;
                    }
                } else {
                    this.showSurveyModal = false;
                }
            }
        },
        startTimeCheck() {
            // Immediately check the time when the app starts
            this.runSurveyCheck();

            // Set an interval to check the time every 30
            this.intervalId = window.setInterval(() => {
                this.runSurveyCheck();
            }, 30000); // 60000 ms = 1 minute
        },
        scheduleTimeCheckForSurvey() {
            const businessStore = useBusinessStore();

            const hasSubmittedSurvey =
                businessStore.activeBusiness?.has_submitted_weekly_survey;
            if (!hasSubmittedSurvey) this.showSurveyCard = true; // show card irrespective of days
            const now = new Date();
            const nextCheck = new Date();

            // Set the time for 9:59 AM today
            nextCheck.setHours(9, 59, 0, 0);

            // If it's already past 9:59 PM today, schedule for tomorrow
            if (now.getTime() > nextCheck.getTime()) {
                nextCheck.setDate(nextCheck.getDate() + 1);
            }

            // Calculate the time difference in milliseconds
            const timeUntilCheck = nextCheck.getTime() - now.getTime();

            // Use setTimeout to start the interval at 11:59 PM
            setTimeout(() => {
                this.startTimeCheck();
            }, timeUntilCheck);
        },

        terminateSurveyCheck(): void {
            // stop interval if use close button to prevent rerendering when they dont fill
            if (this.intervalId !== null) {
                clearInterval(this.intervalId);
                this.intervalId = null;
            }
            this.showSurveyModal = false;
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
