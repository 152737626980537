// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

import * as components from "vuetify/components";
import * as directives from "vuetify/directives";
import "../assets/css/variable.scss";
// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify({
  defaults: {
    VBtn: {
      style: "text-transform: none;",
    },
  },
  components: {
    ...components,
  },
  icons: {
    defaultSet: "mdi",
  },
  directives,
  theme: {
    themes: {
      light: {
        dark: false,
        colors: {
          primary: "#00AEEF",
          secondary: "#FF3B30",
          accent: "#101928",
          border:"#E4E7EC"
        },
      },
    },
  },
});
