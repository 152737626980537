import { fetchWrapper } from "../helpers/fetch-wrapper";
import { BASE_API } from "../helpers/constants";
import {
  useCallStore,
  useNumberStore,
  useUsersStore,
  useBusinessStore,
  useAddonStore,
} from "../stores";
import {
  generateFilename,
  recommendationData,
  webPurchasePayload,
  formatDateToString,
} from ".";
import { computed } from "vue";
import dayjs from "dayjs";
export interface ResponseData {
  [key: string]: any;
}
interface CustomFetchSipInfo extends RequestInit {
  geofence?: boolean;
  handleError?: boolean;
}
export const getActiveBusinessNumberId = () => {
  const numberStore = useNumberStore();
  return numberStore.activeBusinessNumberId;
};
export const getActiveBusinessId = () => {
  const businessStore = useBusinessStore();
  return businessStore.currentBusinessId;
};
export const getSelectedBusinessNumber = () => {
  // for all business in manager view
  const numberStore = useNumberStore();
  return numberStore.activeBusinessNumberForReports?.id;
};

export const getAddonId = (addonTitle: string) => {
  const addonStore = useAddonStore();
  const addonLists = computed(() => addonStore.getSubscribedAddons);
  const addon = addonLists.value.find((addon) =>
    addon.subscription_plan.title.includes(addonTitle)
  );
  return addon?.id;
};

export const getReceiverId = () => {
  const userStore = useUsersStore();
  const activeBusinessNumberId = getActiveBusinessNumberId();

  return userStore.currentUser?.receivers?.find(
    (x: any) => x.business_number?.id == activeBusinessNumberId
  )?.id;
};

const getUserId = () => {
  const userStore = useUsersStore();
  return userStore?.currentUserBusinessLevel?.id;
};

const getCurrentUserReceiverKey = () => {
  const numberStore = useNumberStore();
  return numberStore.currentUserReceiverKey;
};

const parseData = async (response: ResponseData) => {
  return response instanceof Promise ? (await response)?.data : response?.data;
  // return response instanceof Promise ? { data: (await response)?.data, status: response.status } : { data: response.data, status: response.status };
};

export const verifyLoginToken = async (payload: any): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/auth/token/`, payload);
};
export const encryptKey = async (payload: any): Promise<ResponseData> => {
  return await fetchWrapper.public_post(
    `${BASE_API}/api/v2/auth/encryption-key/`,
    payload
  );
};
export const verifyEncryptedLoginToken = async (
  payload: any
): Promise<ResponseData> => {
  return await fetchWrapper.public_post(
    `${BASE_API}/api/v2/auth/verify-otp`,
    payload
  );
};
export const refreshToken = async (): Promise<ResponseData> => {
  return await fetchWrapper.post(`${BASE_API}/api/auth/token/refresh/`);
};

export const requestLoginOTP = async (payload: any): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/login/`, payload);
};

export const getUserDetailsByToken = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(`${BASE_API}/api/users/me/`);
};

export const getEncryptionKey = async (): Promise<ResponseData> => {
  return await fetchWrapper.public_get(`${BASE_API}/public-key/`);
}

export const loginWithEmail = async (payload: { email: string, password: string }): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/auth/login/email/`, payload);
}

export const sendVerificationEmail = async (payload: { email: string }): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/auth/verification-email/`, payload);
}
// Reset Password API

export const requestResetPaswordEmail = async (payload: { email: string }): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/auth/password/reset/request/`, payload);
}

export const validateVerifyEmail = async (payload: { token: string, uid: string, is_email_verification?: boolean }): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/auth/token/validate/`, payload);

}

export const requestPasswordResetConfirmation = async (payload: { token: string, uid: string, new_password: string }): Promise<ResponseData> => {
  return await fetchWrapper.public_post(`${BASE_API}/api/auth/password/reset/confirm/`, payload);
}
export const changePassword = async (payload: { old_password: string, new_password: string }): Promise<ResponseData> => {
  return await fetchWrapper.post(`${BASE_API}//api/auth/password/change/`, payload);
}


export const getUserDetailsByTokenNewImplementation =
  async (): Promise<ResponseData> => {
    return await fetchWrapper.get(`${BASE_API}/api/user/me/`);
  };
export const updateUserDetailsByTokenNewImplementation = async (payload: {
  home_address: string;
}): Promise<ResponseData> => {
  return await fetchWrapper.patch(`${BASE_API}/api/user/me/`, payload);
};

export const updateFeedback = // flag to show a user has updated feedback sheet
  async (): Promise<ResponseData> => {
    return await fetchWrapper.patch(`${BASE_API}/api/user/feedback/`, {});
  };

export const fetchSipInfo = async (payload: any): Promise<ResponseData> => {
  const options: CustomFetchSipInfo = {
    geofence: true,
    handleError: true,
  };
  return await fetchWrapper.post(
    `${BASE_API}/api/telephony/crm/receiver-line/`,
    payload,
    options
  );
};
export const fetchSipNewInfo = async (payload: any): Promise<ResponseData> => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/receiver-line/`,
    payload
  );
};

export const getCallHistory = async (
  pageSize: string = "",
  link: string,
  agent_id?: number,
  showAllLogs?: boolean // check if it manager
): Promise<ResponseData> => {
  const callStore = useCallStore();
  let requestUrl: string = "";
  if (link) {
    const url = new URL(link);
    link = url.pathname + url.search;
    requestUrl = `${BASE_API}${link}`;
  } else
    requestUrl = `${BASE_API}/api/telephony/calls/?number=${getActiveBusinessNumberId()}`; // normal link without url
  if (!showAllLogs && !link) {
    requestUrl += `&user_id=${agent_id ? agent_id : getUserId()}`; // add userid if you arent showing all the logs
  }
  requestUrl += `&page_size=${pageSize}`; // add page size

  callStore.currentRequestForReload = requestUrl; // stores last request and calls it when reload is called
  return await fetchWrapper.get(requestUrl);
};
export const filterCallHistory = async (filter: any) => {
  const filterString = Object.keys(filter)
    .map((key) => `${key}=${filter[key]}`)
    .join("&");
  const requestUrl: string = `${BASE_API}/api/telephony/calls/?number=${getActiveBusinessNumberId()}&${filterString}`;
  const callStore = useCallStore();
  callStore.currentRequestForReload = requestUrl;
  return await fetchWrapper.get(requestUrl, null);
};
// new implementation
export const getCallHistoryNew = async (
  pageSize: string = "",
  link: string,
  agent_id?: number,
  showAllLogs?: boolean, // check if it manager
  businessId?: string | number, // when toggling in manager
  source?: string // return only agent log in the recent log partvin agent homepage
): Promise<ResponseData> => {
  const callStore = useCallStore();
  const userStore = useUsersStore();

  let requestUrl: string = "";
  let businessNumberId: string | number | undefined; // allow toggle business id in manager since no active business

  businessNumberId = businessId
    ? businessId
    : userStore.user_role === "owner" || userStore.user_role === "manager"
      ? getSelectedBusinessNumber()
      : getActiveBusinessNumberId();
  if (link) {
    const url = new URL(link);
    link = url.pathname + url.search;

    requestUrl = `${BASE_API}${link}`;
  } else
    requestUrl = `${BASE_API}/api/business/${getActiveBusinessId()}/number/${businessNumberId}/call_log/?page_size=${pageSize}`; // normal link without url
  if (
    (!showAllLogs && !link && !userStore.getAgentRole.can_view_all_call_logs) ||
    source === "dashboard"
  ) {
    requestUrl += `&user_id=${agent_id ? agent_id : getUserId()}`; // add userid if you arent showing all the logs
  }

  callStore.currentRequestForReload = requestUrl; // stores last request and calls it when reload is called
  callStore.currentRequestQuery =
    link ||
    `user_id=${agent_id ? agent_id : getUserId()}&page_size=${pageSize}`; // stores last query for download and calls it when reload is called
  callStore.fetchFilter = false;
  return await fetchWrapper.get(requestUrl);
};

export const filterCallHistoryNew = async (
  filter: any,
  businessId?: string | number
) => {
  const filterString = Object.keys(filter).reduce(
    (acc, key) => {
      const value = filter[key];
      if (value !== null && value !== "") {
        //allow only key value pair with value
        const separator = acc.string ? "&" : ""; // Add '&' only if string is non-empty
        const pair = `${separator}${key}=${value}`;
        return {
          string: acc.string + pair,
        };
      }
      return acc; // Skip if value is falsy
    },
    { string: "" }
  ).string; // Extract the filter string from the accumulator object
  let businessNumberId: string | number | undefined; // allow toggle business id in manager since no active business
  // businessNumberId = businessId ?? getActiveBusinessNumberId();
  const userStore = useUsersStore();
  businessNumberId = businessId
    ? businessId
    : userStore.user_role === "owner" || userStore.user_role === "manager"
      ? getSelectedBusinessNumber()
      : getActiveBusinessNumberId(); // for owner and manager use the list of all number that manager is permitted to access
  const requestUrl: string = `${BASE_API}/api/business/${getActiveBusinessId()}/number/${businessNumberId}/call_log/?${filterString}`;
  const callStore = useCallStore();
  callStore.currentRequestForReload = requestUrl;
  callStore.currentRequestQuery = filterString;
  callStore.fetchFilter = true;
  return await fetchWrapper.get(requestUrl, null);
};
export const fetchCallLogDetailsById = async (
  sip_call_id: string,
  businessNumber?: string | number,
  businessId?: string | number
) => {
  const userStore = useUsersStore();

  const businessNumberId = businessNumber
    ? businessNumber
    : userStore.user_role === "owner" || userStore.user_role === "manager"
      ? getSelectedBusinessNumber()
      : getActiveBusinessNumberId();
  const requestUrl: string = `${BASE_API}/api/business/${businessId ? businessId : getActiveBusinessId()
    }/number/${businessNumberId}/call_log/${sip_call_id}`;

  return await fetchWrapper.get(requestUrl, null);
};

function removePageSizeParam(url: string) {
  // remove param from string
  const urlObj = new URL(url);
  urlObj.searchParams.delete("page_size");
  const updatedUrl = urlObj.toString();
  return updatedUrl.replace(/%2C/g, ",");
}

export const exportCallHistory = async (
  allowCustomDate?: boolean,
  dateFilter?: string
) => {
  let requestUrl: string;

  const callStore = useCallStore();
  callStore.currentRequestForReload;
  let exportString = "export/";

  let index = callStore.currentRequestForReload?.indexOf("?");
  requestUrl =
    callStore.currentRequestForReload?.slice(0, index) +
    exportString +
    callStore.currentRequestForReload?.slice(index);
  if (allowCustomDate) requestUrl += `&date_created_range=${dateFilter}`;
  const exportUrl = removePageSizeParam(requestUrl);

  return await fetchWrapper.downloadExcel(
    `${exportUrl}`,
    null,
    `pressone_calls_export_${dayjs().format("DD-MM-YYYY")}`
  );
};

export const downloadBillingHistory = async (
  user_id: string,
  from_date: string,
  to_date: string
) => {
  const fileName = generateFilename(from_date, to_date);
  const business_id = getActiveBusinessId();
  return await fetchWrapper.download(
    `${BASE_API}/api/payments/history/export/?business_id=${business_id}&user_id=${user_id}&from_date=${from_date}&to_date=${to_date}`,
    "",
    fileName
  );
};

// new implementation

export const downloadCallHistory = async (
  user_id: string,
  from_date: string,
  to_date: string
) => {
  const fileName = generateFilename(from_date, to_date);
  return await fetchWrapper.download(
    `${BASE_API}/api/telephony/calls/download/?number=${getActiveBusinessNumberId()}&user_id=${user_id}&from_date=${from_date}&to_date=${to_date}`,
    "",
    fileName
  );
};
export const fetchTeamMembers = async (): Promise<ResponseData> => {
  return await fetchWrapper.get(
    `${BASE_API}/api/receiver_numbers/?business_number=${getActiveBusinessNumberId()}`
  );
};

export const updateUserDetails = async (payload: any) => {
  return await fetchWrapper.patch(
    `${BASE_API}/api/business/${getActiveBusinessId()}/user/${getUserId()}/profile/`,
    payload
  );
};

export const updateUserAvatar = async (formData: FormData) => {
  return await fetchWrapper.formDataPost(
    `${BASE_API}/api/users/update_avatar/`,
    formData
  );
};

export const sendRecommendation = async (
  payload: recommendationData
): Promise<ResponseData> => {
  return parseData(
    await fetchWrapper.public_post(
      `${BASE_API}/api/recommendation-form/`,
      payload
    )
  );
};

export const getRecommendationByEmail = async (email: string): Promise<ResponseData> => {
  return await fetchWrapper.public_get(
    `${BASE_API}/api/recommendation-form/by-email/?email=${email}`
  );
};

export const getRecommendation = async (): Promise<ResponseData> => {
  return await fetchWrapper.getRecommendation(
    `${BASE_API}/api/recommendation-form/`
  );
};


export const updateRecommendation = async (
  presaleId: string,
  payload: any
): Promise<ResponseData> => {
  return await fetchWrapper.patchRecommendation(
    `${BASE_API}/api/recommendation-form/${presaleId}`,
    payload
  );
};
export const campaignPurchase = async (payload?: {
  plan_id: string;
}): Promise<ResponseData> => {
  return parseData(
    await fetchWrapper.post(
      `${BASE_API}/api/payments/number-purchase/campaign/`,
      payload || {}
    )
  );
};

export const getWebPaymentPurchaseLinkSignUp = async (
  payload: webPurchasePayload
): Promise<ResponseData> => {
  return parseData(
    await fetchWrapper.public_post(
      `${BASE_API}/api/payments/number-purchase/web/`,
      payload
    )
  );
};
export const getWebPaymentPurchaseLinkSignUpVersionTwo = async (
  payload: webPurchasePayload
): Promise<ResponseData> => {
  return parseData(
    await fetchWrapper.public_post(
      `${BASE_API}/api/payments/number-purchase/web/v2/`,
      payload
    )
  );
};

export const getWebPaymentPurchaseLinkSignUpForAdditionalPurchase = async (
  payload: webPurchasePayload
): Promise<ResponseData> => {
  return parseData(
    await fetchWrapper.post(
      `${BASE_API}/api/payments/number-purchase/`,
      payload
    )
  );
};
export const registerUser = async (payload: any) => {
  return await fetchWrapper.public_post(
    `${BASE_API}/api/user/register/`,
    payload
  );
};
export const registerUserPassword = async (payload: any) => {
  return await fetchWrapper.public_post(
    `${BASE_API}/api/auth/register/`,
    payload
  );
};


export const getPlans = async (queryString: string = "") => {
  return parseData(
    await fetchWrapper.public_get(
      `${BASE_API}/api/payments/subscription-plan/?page_size=100` + queryString,
      ""
    )
  );
};

export const getActivityReport = async (
  export_csv: boolean,
  from?: string,
  to?: string,
  userId?: number,
  numberId?: number
): Promise<any> => {
  let requestUrl = `${BASE_API}/api/business/report/team/?start_date=${from}&business_id=${getActiveBusinessId()}&end_date=${to}&period=custom&number_id=${numberId}&user_id=${userId}${export_csv ? "/export-csv" : ""
    }`;
  if (from && to) {
    if (export_csv) {
      requestUrl = `${requestUrl}?from_date=${from}&to_date=${to}`;
    }
  }

  return export_csv
    ? await fetchWrapper.download(requestUrl, "", "activity_report.csv")
    : await fetchWrapper.get(requestUrl);
};

export const summaryReportCsv = async (
  custom_start_date: string,
  custom_end_date: string,
  businessNumberId: number
) => {
  const fileTitle = `Pressone summary report(${formatDateToString(
    custom_start_date
  )} - ${formatDateToString(custom_end_date)})`;
  return await fetchWrapper.download(
    `${BASE_API}/api/business/report/team/export-csv/?number_id=${businessNumberId}&business_id=${getActiveBusinessId()}&period=custom&start_date=${custom_start_date}&end_date=${custom_end_date}`,
    "",
    `${fileTitle}`
  );
};

export const fetchCallSummary = async (period: string) => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/report/call-log-summary/?business_id=${getActiveBusinessId()}&period=${period}`
  );
};
export const fetchIndividualCallLength = async () => {
  const userStore = useUsersStore();
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/number/${getActiveBusinessNumberId()}/call_log/?user_id=${userStore.currentUserBusinessLevel?.id
    }`
  );
};
export const hasMadeTestCall = async () => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/number/${getActiveBusinessNumberId()}/call_log/?page_size=25`
  );
};
export const teamMembersReportData = async (
  custom_start_date: string,
  custom_end_date: string
) => {
  return await fetchWrapper.get(
    `${BASE_API}/api/numbers/${getReceiverId()}/team-members-report/?order_by=satisfaction_rate&custom_start_date=${custom_start_date}&custom_end_date=${custom_end_date}&period=custom`
  );
};

export const leaderboardReportData = async (
  from?: string,
  to?: string,
  business_number_id?: string
) => {
  return fetchWrapper.get(
    `${BASE_API}/api/business/report/leaderboard/?number_id=${business_number_id}&business_id=${getActiveBusinessId()}&order_by=total_call&start_date=${from}&end_date=${to}&period=custom`
  );
};

export const downloadLeaderbordReportData = async (
  from?: string,
  to?: string,
  receiverId?: number
) => {
  const fileTitle = `Leaderboard Report (${formatDateToString(
    from!
  )} - ${formatDateToString(to!)})`;
  return fetchWrapper.download(
    `${BASE_API}/api/business/report/leaderboard/export-csv/?business_id=${getActiveBusinessId()}&number_id=${receiverId}&start_date=${from}&end_date=${to}&period=custom&order_by=satisfaction_rate`,
    "",
    `${fileTitle}`
  );
};

export const requestVerificationOTP = async () => {
  return parseData(
    await fetchWrapper.post(`${BASE_API}/api/users/request_verification_otp/`)
  );
};

export const confirmVerificationOTP = async (payload: any) => {
  return parseData(
    await fetchWrapper.post(
      `${BASE_API}/api/users/confirm_verifation_otp/`,
      payload
    )
  );
};

export const verifyAccount = async (payload: any) => {
  return parseData(
    await fetchWrapper.post(
      `${BASE_API}/api/business/request-user-verification/`,
      payload
    )
  );
};

export const updateUserProfile = async (id: number, payload: any) => {
  return parseData(
    await fetchWrapper.patch(`${BASE_API}/api/users/${id}/`, payload)
  );
};

export const getBusinessDetails = async () => {
  return parseData(
    await fetchWrapper.get(`${BASE_API}/api/business/${getActiveBusinessId()}/`)
  );
};

export const updateBusinessDetails = async (payload: any) => {
  return parseData(
    await fetchWrapper.patch(
      `${BASE_API}/api/business/${getActiveBusinessId()}/`,
      payload
    )
  );
};

export const submitGovernmentId = async (payload: any) => {
  return parseData(
    await fetchWrapper.formDataPost(
      `${BASE_API}/api/users/upload_verification_document/`,
      payload
    )
  );
};

export const fetchVoicemail = async (
  params: any,
  businessId?: string | number,
  link?: string
) => {
  if (!link) {
    const filterString = Object.keys(params).reduce(
      (acc, key) => {
        const value = params[key];
        if (value !== null && value !== "") {
          //allow only key value pair with value
          const separator = acc.string ? "&" : ""; // Add '&' only if string is non-empty
          const pair = `${separator}${key}=${value}`;
          return {
            string: acc.string + pair,
          };
        }
        return acc; // Skip if value is falsy
      },
      { string: "" }
    ).string;
    let businessNumberId: string | number | undefined; // allow toggle business id in manager since no active business
    const userStore = useUsersStore();
    businessNumberId = businessId
      ? businessId
      : userStore.user_role === "owner" || userStore.user_role === "manager"
        ? getSelectedBusinessNumber()
        : getActiveBusinessNumberId(); // for owner and manager use the list of all number that manager is permitted to access

    let url = `${BASE_API}/api/business/${getActiveBusinessId()}/voicemail-message/?number_id=${businessNumberId}&page_size=100`;
    if (filterString) url += `&${filterString}`;
    return parseData(await fetchWrapper.get(url, null));
  } else {
    return parseData(await fetchWrapper.get(link, null));
  }
};
export const updateVoicemailStatus = async (voice_message_id: any) => {
  const url = `${BASE_API}/api/business/${getActiveBusinessId()}/voicemail-message/${voice_message_id}/played/`;

  return parseData(await fetchWrapper.patch(url));
};

export const registerInvitedAgent = async (payload: FormData) => {
  return await fetchWrapper.formDataPost(
    `${BASE_API}/api/agent/invite-signup/`,
    payload
  );
};

export const getMobileFromInvite = async (payload: any) => {
  return await fetchWrapper.get(
    `${BASE_API}/api/agent/manager/get_invited_user_details?code=${payload.code}=&nonce=${payload.nonce}`
  );
};

export const getAllBusiness = async () => {
  return parseData(await fetchWrapper.get(`${BASE_API}/api/business/`));
};
export const getUserPermissions = async () => {
  return parseData(
    await fetchWrapper.get(
      `${BASE_API}/api/number/${getActiveBusinessNumberId()}/agent/${getCurrentUserReceiverKey()}/`
    )
  );
};
export const logoutAndClearCookies = async () => {
  return parseData(await fetchWrapper.post(`${BASE_API}/api/auth/logout/`));
};
