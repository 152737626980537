import { defineStore } from "pinia";
import { PINIA_PERSIST_OPTIONS } from "../helpers";
import { useNumberStore, useUsersStore } from ".";
import { notify } from "@kyvg/vue3-notification";
import router from "../router";

export interface BusinessList {
    first_name: string;
    last_name: string;
    email: string;
    role: string;
    business: {
        balance: number | string;
        name: string;
        business_timezone: string;
        id: number;
        verified?: true;
        has_provisioned_first_number: boolean
    };
    user: number;
    business_name: string;
    date_created: string;
    has_submitted_weekly_survey?: boolean;
}
interface BusinessState {
    businesses: BusinessList[] | null; // replace object later
    activeBusiness: BusinessList | null;
    roles: string[];
    industryList: string[];
    activeProfileForm: string;
}
export const useBusinessStore = defineStore({
    id: "business",
    state: (): BusinessState => ({
        businesses: null, // list all business under this agent
        activeBusiness: null,
        roles: [
            "CEO (Chief Executive Officer)",
            "CFO (Chief Financial Officer)",
            "COO (Chief Operating Officer)",
            "CMO (Chief Marketing Officer)",
            "CTO (Chief Technology Officer)",
            "CIO (Chief Information Officer)",
            "CHRO (Chief Human Resources Officer)",
            "CCO (Chief Communications Officer)",
            "CDO (Chief Data Officer)",
            "CSO (Chief Strategy Officer)",
            "CLO (Chief Legal Officer)",
            "CRO (Chief Risk Officer)",
            "President",
            "Vice President",
            "Director",
            "Manager",
            "Supervisor",
            "Team Leader",
            "Project Manager",
            "Product Manager",
            "Program Manager",
            "Business Analyst",
            "Financial Analyst",
            "Data Analyst",
            "Marketing Manager",
            "Sales Manager",
            "Customer Success Manager",
            "Operations Manager",
            "HR Manager",
            "Recruitment Specialist",
            "Training and Development Manager",
            "Talent Acquisition Manager",
            "Compensation and Benefits Specialist",
            "Diversity and Inclusion Manager",
            "Employee Relations Manager",
            "Learning and Development Specialist",
            "Organizational Development Specialist",
            "Talent Management Specialist",
            "Performance Management Specialist",
            "Change Management Specialist",
            "Employee Engagement Specialist",
            "Internal Communications Specialist",
            "Public Relations Manager",
            "Media Relations Specialist",
            "Social Media Manager",
            "Content Manager",
            "Brand Manager",
            "Digital Marketing Specialist",
            "SEO Specialist",
            "SEM Specialist",
            "PPC Specialist",
            "Email Marketing Specialist",
            "Web Developer",
            "Software Engineer",
            "Systems Administrator",
            "Network Engineer",
            "Database Administrator",
            "IT Support Specialist",
            "Cybersecurity Analyst",
            "Cloud Architect",
            "UX/UI Designer",
            "Graphic Designer",
            "Content Writer",
            "Copywriter",
            "Technical Writer",
            "Editor",
            "Proofreader",
            "Researcher",
            "Market Research Analyst",
            "Sales Representative",
            "Account Manager",
            "Customer Support Specialist",
            "Client Success Manager",
            "Business Development Manager",
            "Strategic Partnerships Manager",
            "Procurement Specialist",
            "Supply Chain Manager",
            "Logistics Coordinator",
            "Warehouse Manager",
            "Quality Assurance Specialist",
            "Regulatory Compliance Specialist",
            "Environmental Health and Safety Manager",
            "Risk Management Specialist",
            "Legal Counsel",
            "Contracts Manager",
            "Corporate Secretary",
            "General Counsel",
            "Compliance Officer",
            "Ethics Officer",
            "Internal Auditor",
            "External Auditor",
            "Financial Controller",
            "Tax Manager",
            "Treasury Analyst",
            "Budget Analyst",
            "Credit Analyst",
            "Financial Planner",
            "Investment Analyst",
            "Portfolio Manager",
            "Wealth Manager",
            "Insurance Specialist",
            "Actuary",
            "Underwriter",
            "Claims Adjuster",
            "Mortgage Broker",
            "Real Estate Agent",
            "Property Manager",
            "Facilities Manager",
            "Construction Manager",
            "Architect",
            "Civil Engineer",
            "Mechanical Engineer",
            "Electrical Engineer",
            "Project Engineer",
            "Research and Development Manager",
            "Innovation Manager",
            "Product Development Specialist",
            "Manufacturing Engineer",
            "Production Manager",
            "Quality Control Manager",
            "Process Improvement Specialist",
            "Six Sigma Black Belt",
            "Lean Manufacturing Specialist",
            "Environmental Engineer",
            "Sustainability Manager",
            "Health and Safety Officer",
            "Occupational Therapist",
            "Psychologist",
            "Social Worker",
            "Counselor",
            "Life Coach",
            "Nurse",
            "Physician",
            "Pharmacist",
            "Medical Technologist",
            "Epidemiologist",
            "Health Educator",
            "Nutritionist",
            "Fitness Trainer",
            "Wellness Coach",
            "Sports Psychologist",
            "Recreation Coordinator",
            "Event Planner",
            "Hospitality Manager",
            "Restaurant Manager",
            "Food and Beverage Director",
            "Catering Manager",
            "Tourism Coordinator",
            "Travel Agent",
            "Concierge",
            "Accountant",
        ],
        industryList: [
            "Accounting",
            "Administration & Office Support",
            "Advertising, Arts & Media",
            "Banking & Financial Services",
            "Call Centre & Customer Service",
            "Community Services & Development",
            "Construction",
            "Consulting & Strategy",
            "Design & Architecture",
            "Education & Training",
            "Engineering",
            "Farming, Animals & Conservation",
            "Government & Defence",
            "Healthcare & Medical",
            "Hospitality & Tourism",
            "Human Resources & Recruitment",
            "Information & Communication Technology",
            "Insurance & Superannuation",
            "Legal",
            "Manufacturing, Transport & Logistics",
            "Marketing & Communications",
            "Mining, Resources & Energy",
            "Real Estate & Property",
            "Retail & Consumer Products",
            "Sales",
            "Science & Technology",
            "Sport & Recreation",
            "Trades & Services",
            "Others",
        ],
        activeProfileForm: "account",
    }),
    getters: {
        currentBusinessId(): number | string {
            // current business id.
            return this.activeBusiness ? this.activeBusiness.business.id : "";
        },
        activeProfile(): any {
            // return current business after update. filter the businesslist based on the id of active business since the businesslist is more reactive. This fixes the issue of profile not updateing immediately
            return this.businesses?.find(
                (business) =>
                    business?.business.id === this.activeBusiness?.business.id
            );
        },
        currentUserId(): number | string {
            return this.activeBusiness ? this.activeBusiness.user : "";
        },
        getInactiveBusinesses: (state) =>
            state.businesses?.filter(
                (business: BusinessList) =>
                    business.business?.id != state.activeBusiness?.business?.id
            ),
        listOfRecievers: () => {
            return Array.from({ length: 10 }, (_, k) => k + 1);
        },
        goGlobalFirstSignIn(): any {
            return this.activeProfile?.business?.is_go_global &&
                !this.activeProfile?.business?.has_filled_engagement_form
        }
    },
    actions: {
        getBusiness() {
            // retrieve the list of businesses
            const userStore = useUsersStore();
            this.businesses = userStore.userProfile;
            if (this.businesses?.length && !this.activeBusiness) {
                // sets the first element in the array to the active number if no active number exists
                this.activeBusiness = this.businesses[0];
            }
        },
        async setActiveBusiness(
            business: BusinessList,
            refresh: boolean = true
        ) {
            const numberStore = useNumberStore();
            const userStore = useUsersStore();

            numberStore.activeNumber = null;
            numberStore.activeBusinessNumber = null;
            numberStore.activeBusinessNumberForReports = null;
            this.activeBusiness = null;
            this.activeBusiness = business;
            userStore.user_role = this.activeBusiness.role;
            notify({
                type: "success",
                duration: 1500,
                title: `Business Transition Successful`,
                text: `Your primary business has successfully transitioned to ${business?.business?.name}.`,
            });
            if (refresh) {
                if (window.location.href.includes("add-new-call-flow")) {
                    router.push("/managers/incoming-flow");

                    setTimeout(() => {
                        router.go(0);
                    }, 100);
                } else {
                    router.go(0);
                }
            }
        },
        async refreshActiveBusiness() {
            // when a new business is bought
            const userStore = useUsersStore();
            let business = userStore.userProfile[0];

            await this.setActiveBusiness(business, false);
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
