import { BASE_API } from "../../constants";
import { fetchWrapper } from "../../fetch-wrapper";
import { getActiveBusinessId } from "../customerengagement";

export const fetchNumbers = async (queries: string = "") => {
  return await fetchWrapper.public_get(
    `${BASE_API}/api/available_number/numbers/` + `${queries}`
  );
};

export const fetchGlobalPlans = async (queries: string = "") => {
  return await fetchWrapper.public_get(
    `${BASE_API}/api/payments/subscription-plan/` + `${queries}`
  );
};

export const purchaseInternationalNumber = async (payload: any) => {
  return await fetchWrapper.public_post(
    `${BASE_API}/api/payments/number-purchase/web/international/`,
    payload
  );
};

export const purchaseAuthInternationalNumber = async (payload: any) => {
  return await fetchWrapper.post(
    `${BASE_API}/api/payments/number-purchase/international/`,
    payload
  );
};

export const fetchGlobalAddress = async (queries: string = "") => {
  return await fetchWrapper.public_get(
    `${BASE_API}/api/available-address/` + `${queries}`
  );
};

export const purchaseAddress = async (payload: any) => {
  if (getActiveBusinessId()) {
    return await fetchWrapper.post(
      `${BASE_API}/api/payments/addon-purchase/`,
      payload
    );
  } else {
    return await fetchWrapper.public_post(
      `${BASE_API}/api/payments/addon-purchase/web/`,
      payload
    );
  }
};

export const purchaseNumberAndAddress = async (payload: any) => {
  return await fetchWrapper.public_post(
    `${BASE_API}/api/payments/number-addon-purchase/web/`,
    payload
  );
};

export const fetchPurchasedAddress = async (queries: string = "") => {
  return await fetchWrapper.get(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/address/` +
      `${queries}`
  );
};

export const cancelAddressRenewal = async (id: string = "") => {
  return await fetchWrapper.post(
    `${BASE_API}/api/business/${getActiveBusinessId()}/addons/address/${id}/`,
    null
  );
};

export const getAddonPlans = async (queries: string = "") => {
  return await fetchWrapper.public_get(
    `${BASE_API}/api/payments/addon-plan/` + `${queries}`
  );
};
