import { TeamMember, InviteTeamMemberPayload } from "./../helpers/types/teams";
import { defineStore } from "pinia";
import { useNumberStore } from "./numbers.store";
import { useUsersStore } from "./users.store";

interface TeamState {
    teamMembers: TeamMember[];
    teamMembersList: TeamMember[];
    showInvitationModal: boolean;
    selectedTeamMember?: any;
    selectedTeamMemberReceiverId?: number | null;
    refetchCallQueue?: boolean;
    hasSuccessfullyInvitedMember?: boolean;
    getRecieversUnderABusiness: any; // used for transfer
    selectedContactQueueTeamMembers?: [];
    addNewTeamMemberPayload: InviteTeamMemberPayload | null;
}

export const useTeamStore = defineStore({
    id: "teammember",
    state: (): TeamState => ({
        teamMembers: [],
        teamMembersList: [],
        showInvitationModal: false,
        selectedTeamMember: null, // this is the user id of the selected team member
        selectedTeamMemberReceiverId: null, // this is the receiver id of the selected team member
        refetchCallQueue: false,
        hasSuccessfullyInvitedMember: false,
        getRecieversUnderABusiness: [],
        selectedContactQueueTeamMembers: [],
        addNewTeamMemberPayload: null,
    }),

    getters: {
        getAllTeamMembers: (state: TeamState) => {
            const { activeNumber } = useNumberStore();
            return [...state.teamMembers, activeNumber];
        },
        activeTeamMembers: (state: TeamState) => {
            // move this to store to be reused anywhere
            const userStore = useUsersStore();
            let results = state.teamMembers
                .filter(
                    (member) =>
                        member.user !==
                            userStore.currentUserBusinessLevel?.id &&
                        member.is_active
                )
                .slice(0, 2); // Default to the first 3 active members

            // If no active members are found, return an empty array
            if (results.length < 1) {
                return [];
            }

            return results;
        },
    },
    persist: {
        storage: localStorage,
        paths: ["addNewTeamMemberPayload","hasSuccessfullyInvitedMember"],
    },
});
