import { defineStore } from "pinia";
import { Subscription } from "../../helpers";
import { PINIA_PERSIST_OPTIONS, processAddons } from "../../helpers";
import {
    getAllAddonList,
    getSubscribedAddonList,
} from "../../helpers/queries/add-ons";
import { getSubscriptionDetails } from "../../helpers/billings/index.ts";
import { notify } from "@kyvg/vue3-notification";

interface ISubscriptionPlan {
    standard_cost: number;
    number_region: string;
    id: number;
    title: string;
    standard_cost_currency: string;
    term: string;
    international_number_enabled: boolean;
    extensions_enabled: boolean;
    extensions_limit: number;
    plan_type: string;
    payment_type: string;
    description: string;
}

export interface Addons {
    title: string;
    cost: number;
    description: string;
    id: number;
    subscription_plan: ISubscriptionPlan;
    subscribedId: number;
    subscription: Subscription;
    isPurchased: boolean;
    standard_cost: number;
    business_number?: BusinessNumber;
    addon_type?: string;
    payment_type?: string;
}
interface BusinessNumber {
    country_code: string;
    label: string;
    id: number;
    phone_number: string;
    plan: number;
    reclaim_date: string;
    date_created: string;
}
interface AddonState {
    allAddons: Addons[];
    subscribedAddons: Addons[];
    selectedAddon: Addons | null;
    userGuides: any;
    subscriptionTerm: string;
    salesCampaign: {
        pendingCampaignId: number | null;
        oldNumberId: number | null;
        selectedNumber: string | null;
    }
}

export const useAddonStore = defineStore({
    id: "add-ons",
    state: (): AddonState => ({
        allAddons: [],
        subscriptionTerm: "",
        subscribedAddons: [],
        selectedAddon: null,
        userGuides: {
            liveWidget: {
                videoLink:
                    "https://www.youtube.com/embed/ZXJaYBvVLkc?si=2J6_WImNbwMO5wEp",
                text: "How to set up Website Live Call",
                desc: `Setting up your live call widget is quick and easy!
            Follow our simple step-by-step guide to integrate the widget with
            your website and connect your Pressone account.`,
                height: "180",
                width: "250",
                userGuidePdf:
                    "https://pressone.slite.page/p/clNnKs_bTrjiKD/PressOne-website-Live-Call-User-Guide",
            },
            voiceOtp: {
                videoLink:
                    "https://www.youtube.com/embed/nCAk1TLCXds?si=TI3PYO8gnIF24WHe",
                text: "How to set up Voice OTP Add-On",
                desc: " We will walk you through the step-by-step process of how setup your addon.",
                height: "130",
                width: "280",
            },
            textToSpeech: {
                videoLink:
                    "https://www.youtube.com/embed/Ijxx7KnCuhQ?si=wuOCAkrcBVa8S6RO",
                text: "How to set up Text to Speech Add-On",
                desc: " We will walk you through the step-by-step process of how setup your addon.",
                height: "130",
                width: "280",
            },
        },
        salesCampaign: {
            pendingCampaignId: null,
            oldNumberId: null,
            selectedNumber: null,
        },
    }),

    getters: {
        getAllAddons: (state) => state.allAddons,
        getSubscribedAddons: (state) => state.subscribedAddons,
        getSubscriptionTerm: (state) => state.subscriptionTerm,
        getSelectedAddon: (state) => state.selectedAddon,
        getAllEnhancedAddons: (state) => {
            const enhancedAddons = state.allAddons.map((addon) => {
                const purchased = state.subscribedAddons.find(
                    (purchased) => purchased.subscription_plan.id === addon.id
                );
                const isPurchased = !!purchased;
                const subscribedId = purchased ? purchased.id : 0;

                // const subscrbedId = purchasedAddons.value.map((purchased) => purchased.id);
                // console.log(subscrbedId, "isPurchased");

                const res = {
                    ...addon,
                    isPurchased,
                    subscribedId,
                };

                return res;
            });
            return processAddons(enhancedAddons)
        },
    },

    actions: {
        async fetchSubscriptionTerm() {
            try {
                const response = await getSubscriptionDetails();
                console.log(response)
                this.subscriptionTerm = response?.data?.data.term;
            } catch (error) {
                notify({
                    text: `${error}`,
                    type: "error",
                });
            }
        },
        async fetchAllAddons() {
            try {
                const res = await getAllAddonList(this.subscriptionTerm || "monthly");
                this.allAddons = res?.data?.data || [];
            } catch (error) {
                console.error("Failed to fetch all addons:", error);
            } finally {
                // this.loading = false;
            }
        },
        async fetchSubscribedAddons() {
            //   this.loading = true;
            try {
                this.fetchSubscriptionTerm()
                const res = await getSubscribedAddonList();
                this.subscribedAddons = res?.data?.data || [];
            } catch (error) {
                console.error("Failed to fetch subscribed addons:", error);
            } finally {
                // this.loading = false;
            }
        },
        async fetchAllData() {
            await this.fetchSubscriptionTerm();
            await Promise.all([
                this.fetchAllAddons(),
                this.fetchSubscribedAddons(),
            ]);
        },
    },
    persist: PINIA_PERSIST_OPTIONS,
});
