import { defineStore } from "pinia";
import {
    PINIA_PERSIST_OPTIONS,
    fetchBusinessNumbersUnderABusiness,
    fetchReceiversByBusiness,
} from "../helpers";
import { useUsersStore } from ".";
import { notify } from "@kyvg/vue3-notification";
import router from "../router";

type BusinessNumber = {
    id: number;
    phone_number: string;
    label: string;
    plan: number;
    reclaim_date: string | null;
    date_created: string;
};

interface Numbers {
    line: null;
    business_number: BusinessNumber;
    id: number;
    extension_code: number;
    min_call_duration_limit: number | null;
    max_call_duration_limit: number | null;
    date_invited: string;
}
interface AllBusinessNumbers {
    verification_status: string;
    label: string;
    country_code: string;
    id: number;
    phone_number: string;
    forward_calls_to_number: string;
    verified: boolean;
    parent_number: null | string;
    max_receivers: number;
    is_primary: boolean;
    has_greetings_added: boolean;
    plan: number;
    reclaim_date: null | string;
    date_created: string;
}
interface NumberState {
    numbers: any[] | null; // replace type later
    activeNumber: any | null;
    receivers: any;
    businessNumbers: any;
    allBusinessNumbers: AllBusinessNumbers[] | [];
    activeBusinessNumber: AllBusinessNumbers | null;
    activeBusinessNumberForReports: AllBusinessNumbers | null;
    subscriptionStatus: string | null;
    allNumbersRegisteredUnderABusiness: AllBusinessNumbers[] | null;
}
export const useNumberStore = defineStore({
    id: "numbers",
    state: (): NumberState => ({
        numbers: null, // list all reciever numbers under this agent
        activeNumber: null,
        receivers: null,
        businessNumbers: null, // not needed for now.
        allBusinessNumbers: [],
        activeBusinessNumber: null,
        activeBusinessNumberForReports: null, // prevent re render everytime we change route
        subscriptionStatus: null,
        allNumbersRegisteredUnderABusiness: null,
    }),
    getters: {
        currentUserReceiverKey(): number | undefined {
            return this.activeNumber?.id;
        },
        activeBusinessNumberId(): number | string {
            // active business number id. Dont want to change the name cause it might break alot of things
            if (!this.activeNumber) {
                return "";
            }
            return this.activeNumber.business_number.id;
        },
        activeAllBusinessNumberId(): number | string {
            // active business number for all numbers id.
            if (!this.activeBusinessNumber) {
                return "";
            }
            return this.activeBusinessNumber.id;
        },
        getOrderedBusinessNumbers(): Numbers[] {
            if (!this.numbers) {
                return [];
            }

            const sortedNumbers = [...this.numbers].sort((a, b) => {
                if (this.activeNumber && a.id === this.activeNumber.id) {
                    return -1;
                } else if (this.activeNumber && b.id === this.activeNumber.id) {
                    return 1;
                } else {
                    return 0;
                }
            });

            return sortedNumbers;
        },
        getInactiveBusinessNumbers(): Numbers[] {
            return this.getOrderedBusinessNumbers.slice(1);
        },
        showIntegration(): any {
            if (this.allBusinessNumbers) {
                const validPlans = [21, 25, 26, 32, 33, 31, 34];
                const response = this?.allBusinessNumbers.find((number) => {
                    return validPlans.includes(number?.plan);
                });
                return !!response;
            }
        },
        requiresNINVerification():boolean{
        return this.allBusinessNumbers.some((number)=>number.country_code=="NG")
        },
        getAllNumbersRegisteredUnderABusiness(): AllBusinessNumbers[] {
            return this.allNumbersRegisteredUnderABusiness!;
        }
    },
    actions: {
        getUserPhones() {
            // no longer needed. remove later
            const userStore = useUsersStore();
            this.numbers = userStore.currentUser?.receivers;
            if (this.numbers?.length && !this.activeNumber) {
                // sets the first element in the array to the active number if no active number exists
                this.activeNumber = this.numbers[0];
            }
        },
        async getUserPhonesFromBusiness() {
            const userStore = useUsersStore();
            // this new one uses endpoint to get numbers of only the active business
            const response = await fetchReceiversByBusiness(); // returns receiver credentials and business numbers of current user under the current business


            const allBusiness = await fetchBusinessNumbersUnderABusiness(); // return all businesses owned by owner or amnager. Allow manager to see all info even when they arent receivers under them
            this.allNumbersRegisteredUnderABusiness =
                allBusiness.data as AllBusinessNumbers[];
            const allowedBusiness =
                response.data?.permission?.business_numbers;
            // test push
            this.allBusinessNumbers = allBusiness.data.filter(
                (item: AllBusinessNumbers) =>
                    allowedBusiness?.includes(item.id)
            ) as AllBusinessNumbers[]; // filter only allowed business i.e business granted permissions

            this.activeBusinessNumber = this.allBusinessNumbers
                ? this.allBusinessNumbers[0]
                : null;
            this.activeBusinessNumberForReports = this.activeBusinessNumber

            userStore.permissions = response.data.permission;
            this.numbers = response.data.receivers; // store the receivers and business numbers. whether added or owned
            // this.businessNumbers = res.data; // store the numbers under a business

            if (this.numbers?.length! > 0) {
                if (!this.activeNumber) {
                    // sets the first element in the array to the active number if no active number exists
                    this.activeNumber = this.numbers?.[0];
                }
            } else this.activeNumber = null;
        },
        async setActiveNumber(number: any, refresh: boolean = false) {
            this.activeNumber = number;
            notify({
                type: "success",
                duration: 1500,
                title: `Default number switch successful`,
                text: `Your default number has been switched to ${number.business_number.phone_number}.`,
            });
            if (refresh) {
                router.go(0);
            }
        },
        async refreshActiveNumber() {
            const userStore = useUsersStore();
            let number = userStore.currentUser?.receivers[0];
            await this.setActiveNumber(number, false);
        },
        getBusinessNumberIdByNumber(number: string) {
            const business = this.allBusinessNumbers.filter((businessNumber) => businessNumber.phone_number === number)

            return business[0]?.id
        }
    },
    persist: PINIA_PERSIST_OPTIONS,
});
